import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import ClaimResource from 'resources/public/ClaimResource';
import ThemeResource from 'resources/public/ThemeResource';

import ClaimActions from './ClaimActions';

import PublicLayout from 'components/layouts/Public';
import RewardPreview from 'components/RewardPreview/index';
import SocialMetaTags from 'components/SocialMetatags';

import formattedDate from 'utils/formattedDate';
import { defaultReward } from 'utils/whitelabelConsts';

import { t } from 'i18n/index';

function getClaimTitle(category) {
  const title = {
    "course": t('publicClaimPage:claimYourCertificate'),
    "event": t('publicClaimPage:claimYourBadge'),
  }

  return title[category] || t('publicClaimPage:claimYourCollectible');
}

function getClaimSubtitle(category) {
  const subtitle = {
    "course": t('publicClaimPage:addCertificateFirstText'),
    "event": t('publicClaimPage:addBadgeFirstText'),
  }

  let header = [];
  header.push( subtitle[category] || t('publicClaimPage:addCollectibleFirstText'));
  header.push(t('publicClaimPage:addBadgeSecondText'));

  return header.join(' ');
}

const ClaimReward = () => {
  const params  = useParams();
  const tokenId = params.tokenId;
  const resetToken = params.resetToken;
  const organizationId = params.organizationId;
  const preview = window.location.href.includes("preview");
  const urlParams = new URLSearchParams(window.location.search);
  const claimingGift = urlParams.get('gift') === 'true';

  let reward;
  if (tokenId) {
    reward = useSuspense(ClaimResource.detail(), {id: tokenId});
  } else {
    reward = defaultReward;
  }

  const id = tokenId ? reward.organization.id : organizationId;
  
  let theme;

  if (preview) {
    const response = useSuspense(ThemeResource.list(), {id: id, draft: preview})
    if (response[0].draft_theme && response[0].draft_theme.id){
      theme = response[0].draft_theme
    } else {
      theme = response[0].published_theme
    }
  } else {
    theme = useSuspense(ThemeResource.detail(), {id: id})
  }
  
  const claimTitle = getClaimTitle(reward.course_category);
  const claimSubtitle = !theme ? getClaimSubtitle(reward.course_category) : null;

  const claimActions = <ClaimActions reward={ reward }
                                     tokenId={ tokenId }
                                     resetToken={ resetToken }
                                     claimPhrase={ claimTitle }
                                     claimingGift={ claimingGift }
                        />

  const hideDetails = !reward.participant.has_one && reward.hide_details;
  const showDownloadButtons = reward.participant.has_one;

  return (
    <PublicLayout theme={ theme } >
      <SocialMetaTags title={reward.title}
                      description={reward.description}
                      image_url={reward.image_url}
                      external_url={reward.external_url}

      />

      <div className="header">
        <p>{claimTitle}</p>
        { claimSubtitle && <p>{claimSubtitle}</p>}
      </div>

      <RewardPreview  title={ reward.title }
                      image_url={ reward.image_url }
                      activity_link={ reward.course_link }
                      description={ reward.description }
                      metadata={ reward.metadata }
                      reward_type={ reward.category }
                      media_type={ reward.media_type }
                      organization={reward.organization.title}
                      supply_limit={reward.supply_limit}
                      end_date={reward.end_date && formattedDate(reward.end_date)}
                      actions={ claimActions }
                      claimed_at={ Date.now() }
                      activity_title={ reward.course  }
                      activity_type={ reward.course_category }
                      video={ reward.video_url }
                      isWhitelabel={ !!theme }
                      organizationLogo= { theme.organization_logo }
                      showDetails={ !hideDetails }
                      showDownloadButtons={ showDownloadButtons }
        />
    </PublicLayout>
  );
};

export default ClaimReward;
