import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import paymentMethodsDetails from 'utils/claimActions/payments/paymentMethods';

import { Button } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { t } from 'i18n/index';
import { useController } from '@rest-hooks/react';

import PaymentProvidersResource from 'resources/public/PaymentProviderResource';
import LoadingButton from 'components/LoadingButton';

const PaymentOptions = ({handleStripePaymentIntent, handleAdyenPaymentIntent, handleUtrustPaymentIntent, handleEupagoPaymentIntent, organizationId}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const {fetch} = useController();

  useEffect(() => {
    const fetchAvailablePaymentMethods = async () => {
      const response = await fetch(PaymentProvidersResource.detail(), {id: organizationId});
      const paymentMethods = paymentMethodsDetails(handleStripePaymentIntent, handleAdyenPaymentIntent, handleUtrustPaymentIntent, handleEupagoPaymentIntent, response.providers)
      setPaymentMethods(paymentMethods);
    };
  
    fetchAvailablePaymentMethods();
  }, [])
  
  return (
    <div>
          <h1>{t('paymentProviderSelectionModal:title')}</h1>
          {paymentMethods.length === 0 ?
            <LoadingButton />
          :
          <>
            <p>{t('paymentProviderSelectionModal:paymentOption')}</p>

            <div className='payment-provider-modal-container'>
              {paymentMethods.map((method, index) => (
                <Button key={index} fullWidth className='payment-method-button' onClick={method.onClick}>
                  <div className='payment-method-button-icon-container'>
                    <img src={method.icon} alt={`${method.label} icon`} className='payment-method-button-icon' />
                  </div>
                  <div className='payment-method-button-label-container'>
                    <span className='payment-method-button-label'>{method.label}</span>
                  </div>
                  <div className='payment-method-button-chevron-container'>
                    <ChevronRightIcon className='payment-method-button-chevron' />
                  </div>
                </Button>
              ))}
            </div>
          </>
          }
        </div>
  );
};

PaymentOptions.propTypes = {
    handleStripePaymentIntent: PropTypes.func,
    handleAdyenPaymentIntent: PropTypes.func, 
    handleUtrustPaymentIntent: PropTypes.func,
    handleEupagoPaymentIntent: PropTypes.func,
    organizationId: PropTypes.number,
};

export default PaymentOptions