import React, { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useController, useSuspense } from '@rest-hooks/react';

import OfferResource from 'resources/organization/OfferResource';

import StatusModal from 'components/StatusModal';
import ConfirmDialog from 'components/ConfirmDialog';
import WithPermission from 'components/WithPermission';
import OffersFallback from 'components/fallbacks/Offers';
import Layout from 'components/layouts';

import { useToast } from 'utils/context/ToastContext';
import customToast from 'utils/customToast';

import Form from './form'
import { contentType } from 'utils/offersFormHelper';

const pageInfo = {
  name: "Edit offer"
};
const UpdateOffer = () => {

    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);

    const { fetch } = useController();
    const params = useParams();
    const history = useHistory();

    const organizationId = parseInt(params.organizationId);
    const offerId = parseInt(params.offerId);

    const offer = useSuspense(OfferResource.detail(), { id: offerId, organization_id: organizationId });
    const isDisabled = offer.redeemed > 0 ? true : false;

    /*HANDLE FUNCTIONS*/
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const handleValidation = (offer, reveal_file) => {
        const {title, description, course_id, blockchain, contract_address, content_type, content_collections, content_text, reveal_image_attached} = offer;
        validateTitle(title);
        validateDescription(description)
        validateAttributes(course_id, blockchain, contract_address);
        validateContent(content_type, content_collections, content_text, reveal_file, reveal_image_attached);
    };
    
    /*VALIDATION FUNCTIONS*/
    const validateTitle = (title) => {
      if (title.trim() === ""){
        throwError("Please provide a title.");
      }
    };

    const validateDescription = (description) => {
      if (description.trim() === ""){
        throwError("Please provide a description.");
      }
    };

    const validateAttributes = (course_id, blockchain, contract_address) => {
      if (!course_id && (!blockchain || !contract_address)) {
        throwError("Please complete the eligibility settings.");
      }
    };

    const validateContent = (content_type, content_collections, content_text, reveal_file, reveal_image_attached) => {
      if (content_type === contentType.reveal && content_collections.length <= 0) {
        throwError("If you would like to set this offer's content as reveal please select at least one associated collection.");
      } else if (( !reveal_file && !reveal_image_attached ) && content_type === contentType.reveal) {
        throwError("If you would like to set this offer's content as Reveal, please upload an image to replace that of the reward used to redeem the offer.");
      } else if (content_type === contentType.text && (!content_text || content_text.trim() === "")) {
        throwError("If you would like to set this offer's content as Unlockable Content, please tell us what message you would like us to display on redeem.");
      }
    };

    const throwError = (errorMessage) => {
      setError(errorMessage);
      handleOpenErrorModal();
      throw '';
    };

    const { setOpen, setMessage, setSeverity } = useToast();

    const createFormData = (offer, file, reveal_file) => {
      const formData = new FormData();

      const fields = [
        { key: 'redeem_limit', value: offer.redeem_limit },
        { key: 'end_date', value: offer.end_date },
      ];
      if (file && !isDisabled) {
        fields.push({ key: 'image', value: file });
      }
      if (!isDisabled){
        fields.push(
          { key: 'title', value: offer.title },
          { key: 'description', value: offer.description },
          { key: 'offer_type', value: offer.offer_type },
          { key: 'content_type', value: offer.content_type },

          { key: 'reward_id', value: offer.reward_id },
          { key: 'course_id', value: offer.course_id },

          { key: 'blockchain', value: offer.blockchain },
          { key: 'contract_address', value: offer.contract_address },
          { key: 'eligible_attributes', value: offer.eligible_attributes },

          { key: 'text', value: offer.content_text },
          { key: 'link', value: offer.content_text_link },

          { key: 'collections', value: offer.content_collections },
        )
        if (reveal_file) {
          fields.push({ key: 'reveal_image', value: reveal_file });
        }
      }
      fields.forEach(field => {
        formData.append(field.key, field.value);
      });
      return formData;
    };

    const handleOnSubmit = useCallback(
      async (e, offer, file, reveal_file) => {
        e.preventDefault();
        handleValidation(offer, reveal_file);
        try {
          const formData = createFormData(offer, file, reveal_file);
          const { id } = await fetch(OfferResource.update(), {organization_id: organizationId, id: offer.id}, formData);
  
          // success!
          if (id){
            await fetch(OfferResource.list(), {organization_id: organizationId});
            await fetch(OfferResource.detail(), { id: offerId, organization_id: organizationId });
            customToast('success', 'Offer updated successfully', setOpen, setSeverity, setMessage);
            history.goBack();
          }
        } catch (error) {
          const data = await error.response.json();
          setError(data.errors.base[0]);
          handleOpenErrorModal();
        }
      },
      [fetch],
    );

    const confirmDelete = () => {
      setConfirmModal(true);
    };

    const handleDelete = useCallback(
      async () => {
          try {
              const { id } = await fetch(OfferResource.delete(), { id: offer.id, organization_id: organizationId });
              if ( id ) {
                  fetch(OfferResource.list(), {organization_id: organizationId});
                  customToast('success', 'Offer deleted successfully', setOpen, setSeverity, setMessage);
                  history.goBack();
              }
          } catch (error) {
              const data = await error.response.json();
              setError(data.errors.base[0]);
              handleOpenErrorModal();
          }
      },
      [fetch, organizationId],
    );

    return (
        <Layout context='teacher'
                back={ true }
                pageInfo={ pageInfo } 
                activeMenuItem={ 3 }>

            <WithPermission permission={'hasOffers'} fallback={<OffersFallback />}>
              <Form onSubmit={ handleOnSubmit } onDelete={ confirmDelete } record={ offer } isDisabled={ isDisabled } />

              <ConfirmDialog
                title={`Delete?`}
                open={ confirmModal }
                onConfirm={ handleDelete }
                setOpen={ setConfirmModal }
                >
                Are you sure you want to delete this offer?
              </ConfirmDialog>

              { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}
            </WithPermission>
           
        </Layout>
    );
};

export default UpdateOffer;