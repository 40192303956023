import React, { useCallback, useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { Link, useParams } from "react-router-dom";

import CourseResource from 'resources/organization/CourseResource';
import RewardResource from 'resources/organization/RewardResource';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import BadgeCard from 'components/BadgeCard'
import ActionCard from 'components/ActionCard';
import PageTop from 'components/PageTop';
import ConfirmDialog from 'components/ConfirmDialog';
import StatusModal from 'components/StatusModal';
import ClaimLinkModal from '../Reward/Send/ClaimLinkModal';

import { rewardClaimUrl } from 'utils/urls';
import { isRewardUnavailable } from 'utils/rewards';

import { Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { t } from 'i18n/index';
import { courseType } from 'utils/constants';

/* eslint-disable react/prop-types */
const BadgesGrid = ({rewards, courseId, organizationId, onDelete, certificate, sellableCollectibles, mysteryBox, published}) => (
    <div className="badges-grid grid">
      { rewards.map( ({id, title, category, media_type, image_url, video_url, stats, template:isTemplate, public:isPublic, active_state, supply_limit, end_date}) => (
          <BadgeCard key={ id }
                    id={ id }
                    course_id={ courseId }
                    title={ title }
                    category={ category }
                    media_type={ media_type }
                    image_url={ image_url }
                    video_url={ video_url }
                    stats={ stats }
                    isTemplate={ isTemplate }
                    isPublic={ isPublic}
                    onDelete={ () => onDelete(id) }
                    active_state={ active_state }
                    supply_limit={ supply_limit }
                    end_date={ end_date }
                    mysteryBox= { mysteryBox }
                    published = { published }
          />
        ))
      }

      { sellableCollectibles ? 
        <ActionCard to={ `/organizations/${organizationId}/collections/${courseId}/collectibles/new` } 
                    Icon={ AddCircleOutlineIcon } 
                    title="Add Collectible" />
      : certificate ?
          <ActionCard to={ `/organizations/${organizationId}/collections/${courseId}/collectibles/new` } 
                        Icon={ AddCircleOutlineIcon } 
                        title="Add Certificate" />
        : !published &&
          <ActionCard to={ `/organizations/${organizationId}/collections/${courseId}/collectibles/new` } 
                        Icon={ AddCircleOutlineIcon } 
                        title="Add NFT" />
      
      }

    </div>
);

/* eslint-enable react/prop-types */

const Dashboard = () => {
  const [openClaimLinkModal, setOpenClaimLinkModal] = useState(false);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const { fetch } = useController();
  const params = useParams();
  const courseId = parseInt(params.courseId);
  const organizationId = parseInt(params.organizationId);

  const badges = useSuspense(RewardResource.list(), {organization_id: organizationId, course_id: courseId});
  const course = useSuspense(CourseResource.detail(), {id: courseId, organization_id: organizationId});
  
  const collectibles = badges.filter( ({category}) => category == "collectible")
                           .sort( (a,b) => b.active_state - a.active_state )
  const behaviours = badges.filter( ({category}) => category == "behaviour")
                           .sort( (a,b) => b.active_state - a.active_state )
  const achievements = badges.filter( ({category}) => category == "achievement")
                           .sort( (a,b) => b.active_state - a.active_state )

  const sellableCollectibles = course.category === "sellableCollectibles"
  const learnignCourse = course.category === "course";

  const mysteryBox = course.category === courseType.MYSTERYBOX.value;
  const published = course.published && mysteryBox;

  const handleDelete = useCallback(
    async (rewardId) => {
      await fetch(RewardResource.delete(), { id: rewardId, course_id: courseId, organization_id: organizationId });
    },
    [fetch],
  );

  const handleOpenClaimLinkModal = () => {
    setOpenClaimLinkModal(true);
  };
  
  const handleCloseClaimLinkModal = () => {
    setOpenClaimLinkModal(false);
  };

  const confirmPublish = () => {
    setLoading(true);
    setConfirmModal(true);
  };

  const handlePublish = async () => {
    try {
      const { id } = await fetch(CourseResource.update(), {id: courseId, organization_id: organizationId}, { published: true });
      if (id) {
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      const data = await error.response.json();
      setError(data.errors.base[0]);
      setOpenErrorModal(true);
    }
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const mysteryBoxReward = badges.find(badge => badge.category === 'mysteryBox');
  const unavailable = mysteryBoxReward && isRewardUnavailable(mysteryBoxReward);

  return (
    <Layout context='teacher'>

      <PageTop course={ course }/>
      
      {mysteryBox &&
        <div className='page-top'>
          <div className='button-group'>
            <Button variant="contained" color="primary" component={Link} to={ `/organizations/${organizationId}/collections/${courseId}/${mysteryBoxReward ? `collectible/${mysteryBoxReward.id}/edit?mysteryBox=true` : "collectibles/new?mysteryBox=true"}` }  >
              {!mysteryBoxReward ? "Add" : published ? "See" : "Edit"} Mystery Box Settings
            </Button>
            <Button variant="contained" color="primary" onClick={confirmPublish} disabled={!mysteryBoxReward || behaviours.length <= 0 || published}>
              {published ? "Published" : loading ? "Publishing ..." : "Publish Collection"}
            </Button>
            <Button variant="contained" color="primary" onClick={handleOpenClaimLinkModal} disabled={!published || unavailable}>
              {"Send"}
            </Button>
          </div>
        </div>
      }
      
      {sellableCollectibles && 
        <TabPanels labels={ [`Collectibles (${collectibles.length})`] } >
          <BadgesGrid key={0} rewards={ collectibles } organizationId={ course.organization_id } courseId={ courseId } onDelete={ handleDelete } sellableCollectibles={true}/>
        </TabPanels>
      }

      {learnignCourse && 
        <TabPanels labels={ [`Certificates (${achievements.length})`] } >
          <BadgesGrid key={0} rewards={ achievements } organizationId={ course.organization_id } courseId={ courseId } onDelete={ handleDelete } certificate={true}/>
         </TabPanels>
      }
       
      {!sellableCollectibles && !learnignCourse && 
        <TabPanels labels={ [`NFTs (${behaviours.length})`] } >
          <BadgesGrid key={0} rewards={ behaviours } organizationId={ course.organization_id } courseId={ courseId } onDelete={ handleDelete } mysteryBox= { mysteryBox } published={ published }/>
        </TabPanels>
      }

      {behaviours.length > 0 &&
        <ClaimLinkModal open={openClaimLinkModal}
                        url={mysteryBoxReward && rewardClaimUrl(mysteryBoxReward.claim_token)}
                        onClose={handleCloseClaimLinkModal}
        />
      }

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

      {mysteryBoxReward &&
      <ConfirmDialog
        icon= { <ErrorOutlineIcon color='warning'/> }
        title={ t('publishModal:title') }
        open={ confirmModal }
        onConfirm={ handlePublish }
        setOpen={ setConfirmModal }
        mysteryBox={ true }
        handleClose={ () => setLoading(false) }
        >
        <div> 
          <p dangerouslySetInnerHTML={{ __html: t('publishModal:content') }} />
          <div className='confirm-modal-children-container'>
            <h3>{mysteryBoxReward.title}</h3>
            <img src={ mysteryBoxReward.image_url }/>
            <p> {t('publishModal:totalSupply')}{mysteryBoxReward.supply_limit}</p>
          </div>
        </div>
      </ConfirmDialog>}

    </Layout>
)};

export default Dashboard;