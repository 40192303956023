export const defaultTheme = {
  layout: "sidebar",
  primary_background_color: "#ffff",
  banner: "", //doesn't need to be anything as file is imported in claim page
  banner_size: "220px",
  title_color: "#1a1d1f",
  buy_button_color: "#F75201",
  button_text_color: "#ffff",
  text_color: "#1a1d1f",
  metadata_text_color: "#1a1d1f",
  metadata_box_color: "#ebebeb",
  secondary_background_color: "#fdecef",
  support_image: "", //doesn't need to be anything as default will be null
  title_size: "30px",
  subtitle_size: "16px",
  description_size: "16px",
  padding_page: "80px 60px 160px",
  font_family: "Syne",
  offer_card_background_color: "#ffff",
  offer_card_text_color: "#1a1d1f",
};

const orgs = {
  production: {
    57: "tvi",
    72: "samsys",
    83: "nativiz",
    86: "slb",
    249: "fct",
  },
  staging: {
    119: "slb",
  },
  development: {
    30: "slb",
  }
};

export function themeClasses(isLogin, theme, classLayout) {
  const additionalClasses = [];

  if (isLogin) {
    additionalClasses.push("login");
  } else {
    additionalClasses.push("public");
  }

  if (theme && theme.theme) {
    const env = process.env.REACT_APP_WEB_ENV || process.env.NODE_ENV; // fix for staging env - dont remove
    const id_or_slug = orgs[env][theme.id] || theme.id;
    additionalClasses.push(`theme`); // need both
    additionalClasses.push(`theme-${id_or_slug}`);
  }

  additionalClasses.push(classLayout);
  return additionalClasses.join(' ');
}
