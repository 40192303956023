import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Stepper from './Stepper';
import GiftDetails from './GiftDetails'
import InvoiceDetails from './InvoiceDetails'
import PaymentProviderSelector from './PaymentProviderSelector'
import ProcessingPayment from './ProcessingPayment';
import GiftSent from './GiftSent';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

const CheckoutModal = ({
                        step,
                        handleStep,
                        giftData,
                        handleGiftData,
                        invoiceData,
                        handleInvoiceData,
                        gift,
                        senderName,
                        senderEmail,
                        reward,
                        amount,
                        open,
                        onClose,
                        handleError,
                        loggedIn,
                        handleAuthModal,
                      }) => {
    
    const [errorFindingReward, setErrorFindingReward] = useState(false);
    const [paymentProvider, setPaymentProvider] = useState(null);
    const [paymentIntentId, setPaymentIntentId] = useState(null);

    const handleErrorFindingReward = () => {
        setErrorFindingReward(true);
        handleStep(4)
    };

    const handleReset = () => {
        setPaymentProvider(null);
        setPaymentIntentId(null);
        onClose();
    };

    return (
        <Dialog open={ open } onClose={ handleReset } fullWidth maxWidth="sm"> 
            <DialogContent dividers>
                <Stepper step={ step } gift={ gift } />
                {step === 1 &&
                    <GiftDetails handleInvoiceDetails={ () => handleStep(2) }
                            giftData={ giftData }
                            handleGiftData={ handleGiftData }
                            senderName={ senderName }
                            senderEmail={ senderEmail } /> 
                }
                {step === 2 &&
                    <InvoiceDetails handlePaymentSelector={ () => handleStep(3) }
                            invoiceData={ invoiceData }
                            handleInvoiceData={ handleInvoiceData}
                            gift={ gift }
                            senderName={ senderName ? senderName : giftData && giftData.sender_name }
                            senderEmail={ senderEmail? senderEmail : giftData && giftData.sender_email  } 
                            loggedIn={ loggedIn }
                            handleLogin={() => { 
                                                onClose();
                                                handleAuthModal();
                                               }
                                        }
                     /> 
                }
                {step === 3 &&
                    <PaymentProviderSelector gift={ gift }
                            giftData={ giftData }
                            invoiceData={ invoiceData }
                            reward={ reward }
                            amount={ amount }
                            paymentProvider={ paymentProvider }
                            handlePaymentProvider={ setPaymentProvider}
                            paymentIntentId={ paymentIntentId }
                            handlePaymentIntentId={ setPaymentIntentId }
                            handleError={ handleError }
                            handleErrorFindingReward={ handleErrorFindingReward }
                            handleStep={ handleStep }
                            onClose={ handleReset } /> 
                }
                {step === 4 &&
                    <ProcessingPayment errorFindingReward={ errorFindingReward }
                            gift={ gift }
                            paymentProvider={ paymentProvider } />
                }
                {step === 5 && 
                    <GiftSent />
                }
            </DialogContent>
        </Dialog>
    )
};

CheckoutModal.propTypes = {
    step: PropTypes.number,
    handleStep: PropTypes.func,
    giftData: PropTypes.object,
    handleGiftData: PropTypes.func,
    invoiceData: PropTypes.object,
    handleInvoiceData: PropTypes.func,
    gift: PropTypes.bool,
    senderName: PropTypes.string,
    senderEmail: PropTypes.string,
    reward: PropTypes.object,
    amount: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleError: PropTypes.func,
    loggedIn: PropTypes.bool,
    handleAuthModal: PropTypes.func,
  };

export default CheckoutModal;