import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useController } from "@rest-hooks/react";

import { findRewardInstance } from "utils/claimActions/payments/findRewardInstance";
import { adyenConfiguration } from "utils/claimActions/payments/adyenConfiguration";
import getPublishableKey from "utils/getPublishableKey";

import AdyenCheckout from '@adyen/adyen-web';
import '@adyen/adyen-web/dist/adyen.css';

import { t } from "i18n/index";

const AdyenPaymentForm = ({ method,
                            paymentIntentId,
                            sessionData,
                            sessionId,
                            gift,
                            finalPrice,
                            currency,
                            organizationId,
                            handleStep,
                            handleMessage,
                            handleErrorFindingReward,
                         }) => {

  const { fetch } = useController();
  const history = useHistory();

  const mountComponent = async (sessionData, sessionId, paymentIntentId) => {
    const configuration = {
      ...adyenConfiguration,
      clientKey: getPublishableKey(organizationId, 'adyen'),
      session: {
        id: sessionId,
        sessionData: sessionData,
      },
      amount: {
        value: finalPrice,
        currency: currency,
      },
      onPaymentCompleted: (result) => {
        if (result.resultCode === "Authorised"){
          if (!gift) { 
            handleStep(4);
            findRewardInstance(paymentIntentId, 6, 3000, fetch, history, handleErrorFindingReward)
          } else {
            handleStep(5)
          }
        } else {
          handleMessage(t('checkoutForm:paymentFailedDefault'));
        }
      },
      onError: () => {
        handleMessage(t('checkoutForm:paymentFailedDefault'));
      },
    };

    const checkout = await AdyenCheckout(configuration);

    if (method === 'mbway') {
      checkout.create('mbway').mount('#mbway-container');
    } else if (method === 'card') {
      checkout.create('card').mount('#card-container');
    } else {
      const googlePayComponent = checkout.create('googlepay');
      googlePayComponent
        .isAvailable()
        .then(() => {
          googlePayComponent.mount("#googlepay-container");
        })
        .catch(() => {
          handleMessage(t('checkoutForm:googlePayFailed'));
        });
    }
  }

  useEffect(() => {
    if (sessionData && sessionId && paymentIntentId) {
        mountComponent(sessionData, sessionId, paymentIntentId)  
    }
  }, [sessionData, sessionId, paymentIntentId])
  
  return (
    <div>
      <div id="card-container"></div>
      <div id="mbway-container"></div>
      <div id="googlepay-container"></div>
    </div>
  );
};

AdyenPaymentForm.propTypes = {
  method: PropTypes.string,
  paymentIntentId: PropTypes.string,
  sessionData: PropTypes.string,
  sessionId: PropTypes.string,
  gift: PropTypes.bool,
  finalPrice: PropTypes.number,
  currency: PropTypes.string,
  organizationId: PropTypes.number,
  handleStep: PropTypes.func,
  handleMessage: PropTypes.func,
  handleErrorFindingReward: PropTypes.func,
};

export default AdyenPaymentForm;
