import React from 'react';
import PropTypes from 'prop-types';

import OrderCard from 'containers/student/Profile/Orders/OrderCard';

const OrdersGrid = ({ data, hideDetails }) => {
    
    return (   
        data.map((item, index) => {
            return <OrderCard item={ item } 
                              key={ index } 
                              hideDetails={ hideDetails }
                              />
        })
    );
};

OrdersGrid.propTypes = {
    data:PropTypes.array,
    hideDetails: PropTypes.bool,
};

export default OrdersGrid;
