import { t } from 'i18n/index';

export const rewardType = {
  BEHAVIOUR: {
    value: "behaviour",
    label: t('rewardType:behaviour'),
  },
  ACHIEVEMENT: {
    value: "achievement",
    label: t('rewardType:achievement'),
  },
  COLLECTIBLE: {
    value: "collectible",
    label: t('rewardType:collectible'),
  },
  MYSTERYBOX: {
    value: "mysteryBox",
    label: t('rewardType:behaviour'),
  }
};

export const activityType = {
  tuist:   'Surprise NFT',
};

export const claimState = {
  UNCLAIMED: {
    value : 'unclaimed',
    label: t('studentProfile:collectorsPortal_Unclaimed')
  },
  ONCHAIN: {
    value : 'onchain',
    label: t('claimState:collectorsPortal_Reward_Claimed_OnChain')
  },
  OFFCHAIN: {
    value : 'offchain',
    label: t('claimState:collectorsPortal_Reward_Claimed_OffChain')
  }
};

export const courseType = {
  EVENT : {
    value: "event",
    label: "Event Badges & Proof-of-Attendance"
  },
  SELLABLECOLLECTIBLES : {
    value: "sellableCollectibles",
    label: "Digital Collectibles & Art"
  },
  MYSTERYBOX : {
    value: "mysteryBox",
    label: "Surprise NFT"
  },
  COURSE : {
    value: "course",
    label: "Course Certificates & Awards"
  },
  CHALLENGE : {
    value: "challenge",
    label: "Treasure Hunt & Challenge"
  },
  WORKSHOP : {
    value: "workshop",
    label: "Other"
  },
};
