import React from "react";
import PropTypes from "prop-types";
import MUITextfield from "@mui/material/TextField";

const TextField = ({name, label, value, onChange, disabled, multiline, minRows, type, helperText}) => {
  return (
    <div className={`new-form-field`}>
      <MUITextfield
        fullWidth
        name={ name }
        label={ label }
        color="warning"
        value={ value }
        onChange={ onChange }
        disabled={ disabled }
        multiline={ multiline }
        minRows={ minRows }
        type={ type }
        helperText={ helperText }
      />
    </div>
  );
};

TextField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    multiline: PropTypes.bool,
    minRows: PropTypes.number,
    type: PropTypes.string,
    helperText: PropTypes.string,
};

export default TextField;
