import React, {useState, useEffect} from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from "react-router-dom";

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import StatusModal from 'components/StatusModal';
import SalesFallback from 'components/fallbacks/Sales';

import Stats from './Stats';

import OrderResource from 'resources/organization/OrderResource';
import OrderDownloadResource from 'resources/organization/OrderDownloadResource';

import { downloadCSV } from 'utils/downloadFile';
import { ordersColumns } from 'utils/columns/ordersColumns';

import { t } from 'i18n/index';

const pageInfo = {
  name: t('pageInfo:orders'),
  description: t('pageInfo:ordersDescription'),
}

const Orders = () => {
  const { fetch } = useController();
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const [orders, setOrders] = useState([])
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orders = await fetch(OrderResource.list(), {organization_id: organizationId});
        setOrders(orders)
      } catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }
    };

    fetchData();
  }, [])

  const handleOpenErrorModal = () => {
      setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
  };

  orders.sort(({created_at: a}, {created_at: b} ) => {return a < b ? 1 : -1 });

  const completedOrders = orders.filter(order => order.status === 'completed');
  const numberOfCompletedOrders = completedOrders.length;
  const totalAmountEarned = Math.floor(completedOrders.reduce((total, order) => total + order.total_price, 0) / 100);

  const nftsSold = completedOrders.reduce((total, order) => total + order.items_amount, 0);

  const handleDownload = async () => {
    try {
      const response = await fetch(OrderDownloadResource.list(), {organization_id: organizationId});
      downloadCSV(response, 'orders.csv', 'text/csv');
    } catch (error) {
      setError(error.message);
      handleOpenErrorModal();
    }
  };

  const currency = orders.length > 0 ? orders[0].currency : 'USD';

  return (
    <Layout context='teacher'
            activeMenuItem={ 4 }
            pageInfo={pageInfo}>

      <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>

        <TabPanels labels={[ "Orders" ]} >
          <div>

            <Stats stat1={ { counter: numberOfCompletedOrders } } stat2={ { counter: nftsSold } }  stat3={ { counter: totalAmountEarned } } currency ={currency} />
            <div style={{ flexGrow: 1 }}>
              <DataSelectionTable records={orders}
                                  columns={ordersColumns}
                                  onClick={handleDownload}
              />
            </div>

          </div>
        </TabPanels>

      </WithPermission>

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default Orders;
