import moment from 'moment';
import { courseType } from './constants';

/*REWARD HELPERS*/
export const currencySymbol = {"EUR" : "€", "USD" : "$", "GBP" : "£"};

export  const convertPriceFromCents = (price) => {
    if (!price) {
      return null;
    }
    return (price / 100).toFixed(2);
  };

export const convertPriceToCents = (price) => {
    if (!price) {
      return null;
    }

    const newPrice = typeof(price) === "number" ? `${price}` : price;
    const priceParts = newPrice.split(".");

    if (priceParts.length > 1) {
      if (priceParts[1].length > 1) {
        return parseInt(`${priceParts[0]}${priceParts[1]}`);
      } else {
        return parseInt(`${priceParts[0]}${priceParts[1]}0`);
      }
    } else {
      return price * 100;
    }
  };

export const isRewardUnavailable = (reward) => {
    const atSupplyLimit = reward.supply_limit !== null && reward.supply_limit <= reward.stats["issued"];
    const expiredDate = reward.end_date !== null && moment(reward.end_date) < moment(Date.now());
    return !reward.active_state || atSupplyLimit || expiredDate;
  };

export const getMaxAmount = (reward, issued_count) => {
  if (reward.course_category === courseType.MYSTERYBOX.value) {
    return 1
  } else {
    return Math.min(reward.supply_limit ? reward.supply_limit - reward.stats.issued
      : Infinity, reward.claim_limit - issued_count)
  }
};
