export const adyenConfiguration = {
    environment: 'test',
    analytics: {
      enabled: true
    },
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: false,
        holderNameRequired: false,
        billingAddressRequired: false
      }
    },
    showPayButton: true
  };