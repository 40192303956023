const labels = {
    pt: {

        rewardType:{
            behaviour: 'NFT',
            achievement: 'Certificado',
            collectible: 'Colecionável'
        },

        claimState:{
            collectorsPortal_Reward_Claimed_OnChain: 'Ver NFT',
            collectorsPortal_Reward_Claimed_OffChain: 'Ver NFT'
    },

        studentProfile: {
            collectorsPortal_Profile: "Perfil",
            collectorsPortal_Connect: "Ligar Carteira Web3",
            collectorsPortal_ClaimReward: "Obter NFT",
            collectorsPortal_Claimed: "Adquiridos",
            collectorsPortal_Unclaimed: "Por adquirir",
            collectorsPortal_Footer: "👉 Do you also want to create exclusive NFTs for your community?",
            collectorsPortal_FooterLink: "Click here to build and launch your collection in 5 minutes."
        },

        activeMenu: {
            sidebar_Rewards: "Colecionáveis",
            sidebar_Offers: "Ofertas",
            sidebar_Orders: "Encomendas",
            sidebar_Settings: "Definições",
            sidebar_Help: "Ajuda",
        },

        settings_labels: {
            merch_drops: "Merchandise",
            personal_details: "Detalhes Pessoais"
        },

        merch: {
            delivery_details: "Morada de Entrega",
            details_updated: "Detalhes atualizados",
            incomplete_address: "Por favor insira uma morada válida",
            shirt_size: "Tamanho T-shirt *",
            preferences_details: "Preferências",
            redirectButton: "Continuar"
        },

        actionsMenu:{
            actionsMenuButton: "Desconectar da carteira",
        },

        connectModalAddress:{
            addWalletModal_Title: "Adiciona o teu endereço na Blockchain",
            firstContentLabelExplication: "Por favor insere o teu endereço para receber o NFT.",
            secondContentLabelExplication: "Endereços ENS são suportados.",
            inputLabelDefaul: "Endereço da tua Carteira",
            connectModalButtonCancel: "Cancelar",
            connectModalButtonConnect: "Conectar",
            nearMessage: "Endereço de Carteira NEAR",
            polygonMessage: "Endereço de Carteira Ethereum/Polygon",
            nearOrPolygonMessage: "Endereço de Carteira Ethereum/Polygon ou NEAR",
            errorMessage: 'Por favor insira um endereço de carteira válido',
        },

        connectLaterModal: {
            addWalletModal_TitleLater: "Adiciona a tua carteira Web3",
            connecLaterFirstContentLabelExplication: "Último passo para obteres o teu colecionável digital!",
            connecLaterSecondContentLabelExplication: "Se tiveres uma carteira Web3 (como a Metamask), podes agora guardar o teu colecionável como um NFT on-chain.",
            connecLaterThirdContentLabelExplication: "Se não tiveres uma, não te preocupes! Podes aceder ao teu colecionável através da Crowdclass e associar uma carteira Web3 mais tarde.",
            addWeb3Modal_Later: "Mais tarde",
            addWeb3Modal_ConnectNow: "Associar agora",
        },

        shareRewardModal: {
            title: "Parabéns!🎉",
            description: "Acabaste de obter",
            defaultButton: "Vê e partilha o teu colecionável aqui!",
            downloadButton: "Download PDF",
            certificateButton: "Vê e partilha!",
        },

        tooltip:{
            logout: "sair",
        },

        dropMenuOptions: {
            collectorsPortal_Reward_Menu_ClaimOnChain: "Adicionar a carteira Web3",
            collectorsPortal_Reward_Menu_Share: "Partilhar NFT",
        },

        loginPage: {
            login_EnterMailText: "Inicia sessão para veres e organizares a tua coleção digital.",
            login_ForgotPassword: "Esqueceste-te da Password?",
            login_KeepMeLogged: "Mantém-me logado.",
            login_Password: "Palavra Passe",
            login_Reset: "Faz reset aqui.",
            loginModal_LogMeIn: "Log in",
            loginModal_NoAccountFirst: "Não tens conta?",
            loginModal_NoAccountSecond: "Cria uma agora.",
            loginModal_Title: "Vê a tua coleção!",
            loginPage_LogInButton: "Ver a minha coleção",
            loginPage_Title: "Acede à tua coleção digital",
        },

        signUpPage: {
            signUpModal_AgreeTerms: "Eu aceito os Termos & Condições e a Política de Privacidade da Crowdclass",
            signUpModal_AlreadyHaveAccount: "Já tens conta?",
            signUpModal_ClaimText: "Este colecionável é emitido através da plataforma Crowdclass. Regista-te para o obteres e gerires a tua coleção.",
            signUpModal_Continue: "Continuar",
            signUpModal_GetStarted: "Começa aqui!",
            signUpModal_Name: "Nome *",
            signUpModal_SignIn: "Faz sign in",
        },

        passwordReset: {
            forgotPageTitle: "Esqueceu a password?",
            forgotPageDescription: "It happens to the best of us – we’ll help you reset it and get back on track.",
            forgotPageButton: "Reset Password",
            newAccountPageTitle: "Bem-vindo à Crowdclass!",
            newAccountPageDescription: "Cria uma password para ver e partilhar o teu NFT e gerir a tua coleção!",
            newAccountPageButton: "Sign in",
            sendingInstructions: "Sending...",
            requestInstructions: "Enviar instruções",
            resetInstructions: "Foi enviado um e-mail com instruções para criar a nova password.",
            errorEmailMissing: "Por favor insira um endereço de e-mail válido.",
            errorMissingPassword: "Por favor insira uma password válida.",
            errorMatchingPasswords: "As Passwords não são iguais.",
            success: 'You have sucessfully reset your password. 🎉',
            claiming: 'Claiming your badge now.',
            redirecting: 'Redirecting to your profile.',
        },

        connectWallet: {
            title: "Conectar Carteira Web3",
            chooseWallet: "Escolhe uma carteira para conectar",
        },

        tcLabel:{
            byusing: "Ao usar a Crowdclass está a concordar com os nossos",
            firstPhrase:"Eu aceito os",
            secondPhrase:"Termos & Condições",
            thirdPhrase: "e a",
            fourthPhrase: "Política de Privacidade da Crowdclass",
            fifthPhrase: "Termos de uso",
            sixthPhrase:  "Política de Privacidade",
        },

        metadata: {
            metadata_ActivityTitle: "Titulo Coleção",
            metadata_ActivityType: "Tipo de Coleção",
            metadata_ClaimedAt: "Data de Aquisição",
            metadata_IssuedAt: "Data Emissão",
            metadata_IssuerName: "Emitido por",
            metadata_TokenType: "Tipo da Token",
        },

        publicReward: {
            publicRewardPage_by: "por",
            publicRewardPage_ShareIt: "Partilha nas redes!",
            openMysteryBox: "Abrir Mystery Box!",
        },

        publicClaimPage: {
            aboutCrowdclassTextFirst: "A Crowdclass ajuda marcas, organizações e criadores a conectarem-se com o seu público usando colecionáveis digitais e NFTs.",
            aboutCrowdclassTextSecond: "Queres saber mais?",
            aboutCrowdclasscheckItHere: "Sabe mais aqui.",
            addBadgeFirstText: "Adiciona este colecionável à sua coleção de momentos.",
            addCollectibleFirstText: "Adicione este colecionável à sua coleção de momentos.",
            addCertificateFirstText: "Adicione este certificado à sua coleção de momentos.",
            addBadgeSecondText: "Demora menos de 1 minuto.",
            buy: "Comprar agora",
            claimYourBadge: "Obtém o teu colecionável",
            claimYourCollectible: "Obtém o teu colecionável",
            claimYourCertificate: "Obtém o teu certificado",
            currentlyLoggedIn: "Neste momento estás logado como",
            description: "Descrição",
            logOut: "Log Out?",
            profile: "Ver Coleção.",
            alreadyIssuedText: `Este NFT já se encontra associado à tua conta.`,
            notEligibleText: "Esta conta não é elegível para obter este NFT. Entre em contato com a organização se achar que isso não está correto.",
            preApprovedText: "*O e-mail ou conta Crowdclass deve ser elegível para obter este NFT. Entre em contato com a organização se achar que isso não está correto.",
            priceDescription: "cada",
            unavailable: "Este NFT já não se encontra disponível.",
            onboardingSubtitle1: "Colecionáveis Crowdclass",
            onboardingText1: "Os colecionáveis ​​da Crowdclass são presentes digitais exclusivos emitidos como NFTs, celebrando momentos especiais e experiências únicas da nossa vida.",
            onboardingSubtitle2: "Nunca ouviu falar de NFTs?",
            onboardingText2: "NFT (Non-Fungible-Tokens) são tokens digitais únicos e verificáveis ​​na blockchain. Eles podem representar diferentes ativos digitais, são totalmente sua propriedade e não podem ser falsificados. O processo de obter um NFT com Crowdclass é simples e não requer carteira ou experiência web3.",
            claiming: "A obter",
        },

        checkoutForm: {
            finalAmountHigherThanZero : "Um utilizador apenas pode ter {{claimLimit}} instâncias deste NFT. Por isso, a quantidade de NFTs da tua encomenda foi reduzida de {{amount}} para {{finalAmount}}.",
            paymentSucceeded: "Pagamento aceite!",
            paymentProcessing: "O pagamento está a ser processado.",
            paymentFailed: "Não foi possível processar o pagamento, por favor tenta de novo.",
            paymentFailedDefault: "Aconteceu um erro, por favor tenta de novo. Se o erro persistir contacte o administrador da coleção.",
            collectibleUnavailable: "Este NFT já não se encontra disponível.",
            pay: "Pagar {{finalPrice}} {{currencySymbol}}",
            price: "Preço: ",
            inStock: "Stock máximo: ",
            thankYouForPayment: "Obrigado pela compra",
            lookingForRewardInstance: "Em breve, vais ser redirecionado para o perfil.",
            rewardInstanceNotFound: "Aconteceu um erro. Clica AQUI para ir para o perfil e por favor contacta o administrador da coleção.",
            priceUpdated: "Your order price has also been updated to {{finalPrice}} {{currencySymbol}}.",
            proceedToUtrust: "Take me to xMoney",
            gift: "Isto é uma oferta.",
            giftBeingSent: "We will shortly send you an email with your order details and confirmation that your gift was sent.",
            preparingYourOrder: "A preparar a encomenda...",
            orderStarted: "Deu início à sua encomenda.",
            utrustIntro: "You will be or have been redirected to the xMoney payment widget, compatible with any major crypto wallet. Please follow the instructions and confirm your purchase below when all the steps are completed.",
            utrustInstructions: "If you have not been redirected to the xMoney payment widget, ensure your browser is not blocking pop-ups from Crowdclass or ",
            changedMyMind: "Mudei de ideias",
            paymentComplete: "Finalizar pagamento",
            utrustAwaitingConfirmationParagraph1: "Estamos a aguardar a confirmação do pagamento pela xMoney.",
            utrustAwaitingConfirmationParagraph2: "Será redireccionado para o seu perfil em breve.",
            utrustAwaitingConfirmationParagraph3: "Se preferir, pode fechar esta janela e voltar mais tarde para reclamar o NFT após o pagamento estar confirmado.",
            mbwayAwaitingConfirmationParagraph1: "Estamos a aguardar a confirmação do pagamento.",
            mbwayAwaitingConfirmationParagraph2: "Confirma o teu pagamento na app MB WAY.",
            processingOrder: "Estamos a processar a sua encomenda.",
            googlePayFailed: "Google Pay is not available. Please choose a different payment method.",
            phoneNumber: "Número de Telefone",
        },

        giftModal:{
            title: "Oferece este NFT a alguém especial!.",
            message: "Adicionar detalhes e uma mensagem para a pessoa a quem queres oferecer este NFT.",
        },

        giftForm:{
            nameMissing: "Por favor indica-nos o nome da pessoa a quem queres oferecer este NFT.",
            emailMissing: "Por favor indica-nos o endereço de e-mail da pessoa a quem queres oferecer este NFT.",
            senderEmailMissing: "Diga-nos o seu endereço de e-mail.",
            message: "Mensagem especial (máx 400 carateres)",
            senderName: "O teu nome ",
            senderEmail: "O teu e-mail * ",
            from: "De",
            to: "Para",
            proceed: "Proceder para pagamento",
            name: "Nome recipiente *",
            email: "E-mail recipiente *",
        },

        invoiceForm:{
            title: "Dados de Faturação",
            message: "Por favor insira os seus dados embaixo.",
            personalDetails: "Informação Pessoal",
            senderName: "Nome *",
            vat: "NIF.",
            contact: "Contact details",
            senderEmail: "Email *",
            address: "Endereço de Faturação",
            streetAddress: "Rua",
            city: "Cidade",
            state: "Estado/Distrito",
            postCode: "Código Postal",
            country: "País",
            nameMissing: "Por favor indique-nos o seu nome.",
            emailMissing: "Por favor indique-nos o seu endereço de e-mail.",
            countryMissing: "Por favor indique-nos o seu país.",
            userExists: "Já existe uma conta associada a este email. Por favor use um email diferente ou inicie sessão com a conta existente.",
            errorValidatingEmail: "Ocorreu um erro ao validar o e-mail. Por favor tente de novo.",
        },

        paymentProviderSelector:{
            title: "Métodos de Pagamento",
            message: "Por favor escolha o seu método de pagamento:",
        },
        
        offersLabels: {
            offerPageTitle: 'Ofertas',
            offerPageDescription: 'Aqui podes ver todas as ofertas disponíveis para ti.',
            offerAvailableLabel: 'Disponível',
            redeem: 'Resgatar',
            offerRedeemedLabel: 'Redimidas',
            offerCardHeader: 'disponíveis',
            offerCardLinkLabel: 'Lê todas as condições da oferta em:',
            internalElegilibityLabelReward: 'Podes resgatar esta oferta {{dynamicText}} por seres o detentor dos seguintes colecionáveis:',
            internalElegilibityLabelCourse: 'Podes resgatar esta oferta por seres o detentor de um NFT da coleção:',
            externalEligibilityLabel: 'Para resgatar esta oferta precisas de ter um NFT com os seguintes atributos:',
            publicViewElegilibityLabelReward: 'Para resgatar esta oferta precisas de ter o seguinte NFT:',
            publicViewElegilibityLabelCourse: 'Para resgatar esta oferta precisas de ter um NFT da coleção:',
            contract: 'Contrato:',
            offerCardElegilibityOnce: 'uma vez',
            offerCardElegilibityTimes: 'vezes',
            offerCardOwningLabel: ' - Por:',
            offerCardExpirationDate: 'Expira em:',
            offerCardButton: 'Resgatar oferta',
            offerCardLabelRedeemedState: 'Oferta Redimida',
            offerRedeemedWith: "Oferta redimida com:",
            offerCardLabelRedeemedStateConfirmation: 'Você será contatado em breve',
            noOffers: 'Não há ofertas disponíveis para ti neste momento',
            noOffersRedeemed: 'Ainda não redimiste nenhuma oferta',
            publicOffersTitle: "Ofertas Disponíveis",
            publicOfferSubtitle: "Para redimir as ofertas basta clicar no botão de resgatar oferta!",
            notEligible: "Esta conta não é elegível para redimir esta oferta. Entre em contato com a organização se achar que isso não está correto.",
            reveal: "Revelar conteúdo",
            revealContentLoading: "Estamos a validar o pedido. O conteúdo da oferta será revelado dentro de momentos.",
            description: "Ver descrição"
        },

        userOrders: {
            pageTitle: "Compras",
            pageDescription: "Here you can see and manage all your orders",
            completedOrders: "Terminada",
            pendingOrders: "Pendente",
            cancelledOrders: "Cancelada",
            noCompletedOrdersFound: "No completed orders found.",
            noPendingOrdersFound: "No pending orders found.",
            noCancelledOrdersFound: "No cancelled orders found.",
            referenceNotFound: "Reference no. not found",
            createdAt: "Created at",
            quantity: "Quantity",
            paid: "Paid",
            resendInvoiceButton: "Resend Invoice",
            invoiceSent: "Invoice Sent!",
            invoiceNotSent: "Invoice sending Error",
        },

        redeemModal: {
            firstLabel:'Estás prestes a redimir uma oferta de {{title}} de {{course}}.',
            firstLabelExternal: 'Estás prestes a resgatar uma oferta de {{title}}.',
            secondLabel:'',
            thirdLabel:'Por favor confirma que gostavas de resgatar esta oferta.',
            redeemButtomConfirm: 'Resgatar agora!',
            redeemButtomCancel: 'Mais tarde.',
            redeemSuccess: "Parabéns! Redimiste a tua oferta com sucesso! Vê o teu e-mail com os próximos passos.",
            onChainValidationError: "O colecionável digital associado a esta oferta foi obtido on-chain como um NFT. Infelizmente, a verificação de NFT on-chain ainda não está disponível, mas a nossa equipa está a trabalhar para lançá-la em breve. Entraremos em contato contigo assim que estiver pronto para que possas redimir a tua oferta."
        },

        reveal: {
            revealSoon: "a revelar brevemente...",
        },

        settingsStudentProfilePage: {
            first_name: 'Nome',
            last_name: 'Sobrenome',
            buttonConfirm: 'Atualizar',
            pageInfo: 'Perfil',
            wallet_address: 'Endereço da tua Carteira',
            firstNameMissing: "Por favor diz-nos o teu primeiro nome.",
            portuguese: "Português",
            english: "Inglês",
        },

        singUpForm: {
            nameMissing: "Por favor diz-nos o teu primeiro e último nome.",
            emailMissing: "Por favor insira um email válido.",
            passwordMissing: "Por favor insira uma password válida.",
            dataAgreementMissing: "Antes de prosseguir, por favor lê e concorda com os nossos Termos de Uso.",
        },

        authModal: {
            resetDescription: "Para receberes o teu NFT só precisas de definir a tua password.",
            welcome: "Bem vindo à Crowdclass!",
            internalOfferDescription: "Para redimir esta oferta por favor regista-te na plataforma Crowdclass. Se já tens uma conta na Crowdclass, por favor inicia sessão.",
            externalOfferDescription: "Usa a tua carteira Web3 para te registares na plataforma Crowdclass e redimires esta oferta. Se já tens uma conta na Crowdclass, por favor inicia sessão.",
            nftDescription: "Este NFT é emitido através da plataforma Crowdclass. Regista-te para o obteres e gerires a tua coleção. Se já tens uma conta na Crowdclass, por favor inicia sessão.",
            getStarted: "Começa aqui!",
            welcomeBack: "Bem vindo de volta!",
        },

        pageInfo: {
            settings: "Settings",
            settingsDescription: "Settings page description",

            orders: "Orders",
            ordersDescritpion: "Orders page description",

            offers: "Offers",
            offersDescription: "Offers page description",

            reports: "Reports",
            reportsDescription: "Reports page description",

            community: "Community",
            communityDescription: "Community page description"
        },

        publishModal : {
            no: "Não, rever tudo",
            yes: "Sim, publicar agora",
            content: "Depois de publicar a coleção, <b>não vai ser mais possível editar</b> as definições desta Surprise NFT nem os detalhes dos colecionáveis. Continuar?",
            title: "Pronto para publicar?",
            totalSupply: "Número total de items: ",
        },

        paymentProviderSelectionModal : {
            paymentOption: "Escolha o seu método de pagamento preferido:",
            cardButton: "Pagar com Visa/Mastercard",
            mbwayButton: "Pagar com MBWay",
            cryptoButton: "Pagar com Crypto (através de xMoney)",
            cardDescription: "Pagar com Visa/Mastercard",
            mbwayDescription: "Pagar com MBWay.",
            utrustDescription: "You will be redirected to the xMoney payment widget compatible with any major crypto wallets. It will allow you to pay for your purchase in a safe and seamless way using Bitcoin, Ethereum, Tether or a number of other currencies.",
            utrustLink: "What is xMoney?",
        },

        globalVariables: {
            limitedSupply: "Stock",
            expirationDate: "Data de Expiração",
            no: "Não",
            yes: "Sim",
            clickHere: "Clica Aqui",
            showAll: "Ver Todos",
            filterBy: "Filtrar por",
            organization: "Organização"
        }
    },
};

export { labels }

