import React from 'react';
import PropTypes from 'prop-types';
import Title from './Title';
import Organization from './Organization';
import Media from './Media';
import Description from './Description';
import Metadata from './Metadata';
import Onboarding from './Onboarding';
import ClaimSettings from './ClaimSettings';

import { Divider } from '@mui/material';

import 'assets/css/preview.css';

const RewardPreview = ({
    id, title, description, reward_type, organization, activity_title, activity_link, activity_type, supply_limit,
    end_date, metadata, media_type, image_url, image, video, organizationLogo, actions, claim_state, badge_tx, created_at,
    claimed_at, isWhitelabel, showDetails=true, showOnChainStatus, showDownloadButtons }) => {

  return (
    <div id="reward-preview">
      <Title title={ title }
             showOnChainStatus={ showOnChainStatus }
             id={ id }
             claim_state={ claim_state }
             badge_tx={ badge_tx }
      />

      { !isWhitelabel &&
      <Organization org_mobile={true}
                    organization={ organization }
                    activity_title={ activity_title }
                    activity_link={ activity_link }
                    activity_type={ activity_type }
      />
      }

      <Media id={ id }
             image={ image }
             image_url={ image_url }
             media_type={ media_type }
             title={ title }
             reward_type={ reward_type }
             video_url={ video }
             showDetails={ showDetails }
             showDownloadButtons={ showDownloadButtons }
             organizationLogo={ organizationLogo }
      />

      { !isWhitelabel &&
      <Organization org_desktop={true}
                    organization={ organization }
                    activity_title={ activity_title }
                    activity_link={ activity_link }
                    activity_type={ activity_type }
      />
      }

      { actions }

      { showDetails ?
        <>
          <Description description={ description } />

          <Metadata activity_title={ activity_title }
                    organization={ organization }
                    activity_type={ activity_type }
                    metadata={ metadata }
                    reward_type={ reward_type }
                    created_at={ created_at }
                    claimed_at={ claimed_at }
                    isWhitelabel={ isWhitelabel }
          />
        </>
        :
        <>
          <ClaimSettings supply_limit={ supply_limit } end_date={ end_date } />
          <Divider sx={{marginTop: 5, marginBottom: 5}}/>
          <Onboarding />
        </>
      }
    </div>
  );
};

RewardPreview.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.string,
  reward_type: PropTypes.string,
  organization: PropTypes.string,
  activity_title: PropTypes.string,
  activity_link: PropTypes.string,
  activity_type: PropTypes.string,
  supply_limit: PropTypes.number,
  end_date: PropTypes.string,

  metadata: PropTypes.array,
  media_type: PropTypes.string,
  image_url: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.string,
  organizationLogo: PropTypes.string,

  actions: PropTypes.node,

  claim_state: PropTypes.string,
  badge_tx: PropTypes.string,

  created_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  claimed_at: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  isWhitelabel: PropTypes.bool,
  showDetails: PropTypes.bool,
  showOnChainStatus: PropTypes.bool,
  showDownloadButtons: PropTypes.bool,
};

export default RewardPreview;
