import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useController } from '@rest-hooks/react';
import PropTypes from 'prop-types';

import ClaimResource from 'resources/public/ClaimResource';

import BuyActions from './BuyActions';
import AuthModal from 'components/authForms/AuthModal';
import FeedBackUser from 'components/FeedBackUser';

import LastView from 'utils/localStorage/LastView';
import { getMaxAmount, isRewardUnavailable } from 'utils/rewards';
import { getHelpText } from 'utils/claimActions/helpers';

import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { t } from 'i18n/index';
import CheckoutModal from './CheckoutModal';

/*COMPONENTE*/
const ClaimActions = ({claimingGift, reward, tokenId, resetToken, claimPhrase}) => {
    const [errorMessage, setErrorMessage] = useState(null);
    const [amount, setAmount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [openAuthModal, setOpenAuthModal] = useState(false);
    const [openCheckoutModal, setOpenCheckoutModal] = useState(false);

    const [gift, setGift] = useState(false);
    const [giftData, setGiftData] = useState({});
    const [invoiceData, setInvoiceData] = useState({});

    const [step, setStep] = useState(1);

    const { fetch } = useController();
    const history = useHistory();

    const handleCheckoutModalClose = () => {
      setOpenAuthModal(false);
      setOpenCheckoutModal(false);
    };

    const handleGift = () => {
      if (gift){
        setGiftData({})
      }
      setGift(!gift)
    };

    const handleClaim = useCallback (
      async () => {
        handleCheckoutModalClose();
        try {
          if (claimingGift) {
            LastView.write("student");
            history.push(`/profile`, {from: "claim page"});
          } else {
            setLoading(true);
            const { id } = await fetch(ClaimResource.create(), { id: tokenId });
            if (id) {
              setLoading(false);
              LastView.write("student");
              history.push(`/profile`, {from: "claim page"});
            }
          }

        } catch (error) {
          setLoading(false);
          handleError(error);
        }
      },
      [fetch]
    );

    const handleError = async (error) => {
      const { errors } = await error.response.json();
      const errorMessage = errors ? errors.base[0] : error.message;
      setErrorMessage(errorMessage);
    };

    const handleCheckoutModal = () => {
      gift ? setStep(1) : setStep(2);
      setOpenCheckoutModal(true);
      setOpenAuthModal(false);
    };

    const handleAuthModal = () => {
      setOpenAuthModal(true);
    };

    /*IMPORTANT VARIABLES*/
    const unavailable = isRewardUnavailable(reward);
    const { logged_in, name, email, is_enrolled, can_claim, has_been_issued, issued_count } = reward.participant;

    const showActionButtons = !unavailable && can_claim && !errorMessage;

    const sellable = reward.sellable && !claimingGift;

    const helpText = getHelpText(logged_in, has_been_issued, is_enrolled, reward.is_public, unavailable);
    const maxAmount = getMaxAmount(reward, issued_count);

    const handleBuyButton = () => gift ? handleCheckoutModal() : logged_in ? handleCheckoutModal() : handleAuthModal();

    const handleClaimButton = () => logged_in ? handleClaim() : handleAuthModal();

    return (
        <>
          {showActionButtons &&
            <>
              <div className='actions-container'>
                  { sellable ?
                      <BuyActions price={ reward.price }
                                  currency={ reward.currency }
                                  supply_limit={ reward.supply_limit }
                                  maxAmount={ maxAmount }
                                  amount= { amount }
                                  handleAmount = { setAmount }
                                  onBuy={ handleBuyButton }
                                  onClaimButton={ handleClaimButton }
                                  handleGift={() => handleGift()}
                      />
                      :
                      <Button fullWidth variant="contained" onClick={handleClaimButton} disabled={loading}>
                          { loading ? <>
                              {t('publicClaimPage:claiming')}
                              <CircularProgress color="inherit" size={'15px'} sx={{marginLeft: 2}}/>
                            </>
                            : claimPhrase}
                      </Button>
                  }
              </div>
              {sellable &&
                <CheckoutModal step={ step }
                               handleStep={ setStep }
                               giftData={ giftData }
                               handleGiftData={ setGiftData }
                               invoiceData={ invoiceData }
                               handleInvoiceData={ setInvoiceData }
                               gift={ gift }
                               senderName={ name }
                               senderEmail={ email }
                               reward={ reward }
                               amount={ amount }
                               open={ openCheckoutModal }
                               onClose={ handleCheckoutModalClose }
                               handleError={ handleError }
                               loggedIn={ logged_in }
                               handleAuthModal={  () => setOpenAuthModal(true) }
                               />
              }
              <AuthModal
                        open={ openAuthModal }
                        onClose={ () => setOpenAuthModal(false) }
                        done={ handleClaim }
                        resetToken={ resetToken }
                        sellable={ sellable }
                        handleSuccessfulAuth={ handleCheckoutModal }
                        tokenId={ tokenId }
              />
            </>
            }
            <FeedBackUser
                        errorMessage={ errorMessage }
                        name={ name }
                        isLoggedIn={ logged_in }
                        onHelpText={ helpText }
            />
        </>
    );
};

ClaimActions.propTypes = {
    claimingGift: PropTypes.bool,
    reward: PropTypes.object,
    tokenId: PropTypes.string,
    resetToken: PropTypes.number,
    claimPhrase: PropTypes.string,
};

export default ClaimActions;
