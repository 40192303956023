import React, {useState, useEffect} from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import RewardInstanceResource from 'resources/organization/RewardInstanceResource';
import CollectionResource from 'resources/organization/CourseResource';
import OrganizationOfferInstanceResource  from 'resources/organization/OfferInstanceResource';
import OfferInstanceDownloadResource from 'resources/organization/OfferInstanceDownloadResource';
import RewardInstanceDownloadResource from 'resources/organization/RewardInstanceDownloadResource';
import RewardResource from 'resources/organization/RewardResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import StatusModal from 'components/StatusModal';
import OffersFallback from 'components/fallbacks/Offers';
import WithPermission from 'components/WithPermission';
import Stats from './components/Stats';
import LoadingTable from './components/LoadingTable';

import { downloadCSV } from 'utils/downloadFile';

import { rewardInstancesColumns } from 'utils/columns/rewardInstancesColumns'
import { collectionsColumns } from 'utils/columns/collectionsColumns';
import { offerInstancesColumns } from 'utils/columns/offerInstancesColumns';

import { t } from 'i18n/index';

const pageInfo = {
  name: t('pageInfo:reports'),
  description: t('pageInfo:reportsDescription'),
}

const RewardInstances = () => {
  const { fetch } = useController();
  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const history = useHistory();
  
  const [rewards, setRewards] = useState([]);
  const [rewardInstances, setRewardInstances] = useState([]);
  const [filteredRewardInstances, setFilteredRewardInstances] = useState([])
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [collectionId, setCollectionId] = useState((new URL(document.location)).searchParams.get("collection"))
  const [rewardId, setRewardId] = useState((new URL(document.location)).searchParams.get("collectible"))
  const [offerInstances, setOfferInstances] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      try {

        const rewards = await fetch(RewardResource.list(), {organization_id: organizationId});
        setRewards(rewards)
      
        const collections = await fetch(CollectionResource.list(), {organization_id: organizationId})
        setCollections(collections);

        const rewardInstances = await fetch(RewardInstanceResource.list(), {organization_id: organizationId, course_id: collectionId, reward_id: rewardId});
        setRewardInstances(rewardInstances);

        const offerInstances = await fetch(OrganizationOfferInstanceResource.list(), {organization_id: organizationId});
        setOfferInstances(offerInstances);
      
        setLoading(false);
      } catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }    
    };
    fetchData();
  }, []);

  useEffect(() => {
    const result = rewardInstances.filter(({first_name, last_name, tx, claimed}) => {
        last_name = last_name || ''
        tx = tx || ''
        claimed = claimed || '' ? 'minted' : 'not claimed';

        const lcQuery = query.toLowerCase();   

        return (first_name.toLowerCase().includes(lcQuery) ||
                last_name.toLowerCase().includes(lcQuery)  ||
                tx.toLowerCase().includes(lcQuery) ||
                claimed.toLowerCase().includes(lcQuery));
    });

    setFilteredRewardInstances(result);
  }, [query]);

  const handleFilter = async (collectionId, rewardId) => {
    setLoading(true);

    const rewardInstances = await fetch(RewardInstanceResource.list(), {organization_id: organizationId, course_id: collectionId, reward_id: rewardId});
    setRewardInstances(rewardInstances);

    const searchParams = {};
    if (collectionId) {
      searchParams.collection = collectionId;
    }
    if (rewardId) {
      searchParams.collectible = rewardId;
    }

    const search = new URLSearchParams(searchParams).toString();

    history.push({
      search: `?${search}`
    })
  
    setCollectionId(collectionId);
    setRewardId(rewardId);
    setLoading(false);
  };

  const handleDownload = async (target) => {
    try {
        if (target === "offerInstances"){
          const response = await fetch(OfferInstanceDownloadResource.list(), {organization_id: organizationId});
          downloadCSV(response, 'redeemed_offers.csv', 'text/csv');
        } else if (target === "rewardInstances") {
          const response = await fetch(RewardInstanceDownloadResource.list(), {organization_id: organizationId, course_id: collectionId, reward_id: rewardId });
          downloadCSV(response, 'claimed_nfts.csv', 'text/csv');
        }
    } catch (error) {
        setError(error.message);
        handleOpenErrorModal();
    }
  };

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
  };

  return (
    <Layout context='teacher'
            activeMenuItem={ 2 }
            pageInfo={pageInfo}>

      <TabPanels labels={[ "Claimed NFTs", "Collections Stats",  `Redeemed Offers (${offerInstances.length})`]} >
        <div key={0}>
          { loading ? <LoadingTable />
            :
            <div>
              <Stats rewardInstances={ query.length > 1 ? filteredRewardInstances : rewardInstances } />
              <div style={{ flexGrow: 1 }}>
                <DataSelectionTable records={query.length > 1 ? filteredRewardInstances : rewardInstances}
                                    columns={rewardInstancesColumns}
                                    collections={collections}
                                    rewards={rewards}
                                    onQueryChange={setQuery}
                                    queryString={query}
                                    handleFilter={handleFilter}
                                    collectionId={collectionId}
                                    rewardId={rewardId}
                                    onClick={() => handleDownload("rewardInstances")}
                                    />
              </div>
            </div>
            }
        </div>

        <div key={1}>
          { loading ? <LoadingTable />
            : 
          <div style={{ flexGrow: 1 }}>
            <DataSelectionTable records={collections}
                                columns={collectionsColumns}
                                handleLoading={setLoading}
            />
          </div>
          }
        </div>

        <div key={2}>
          <WithPermission permission={'hasOffers'} fallback={<OffersFallback />}>
            { loading ? <LoadingTable />
              : 
            <div style={{ flexGrow: 1 }}>
            <DataSelectionTable records={offerInstances}
                                columns={offerInstancesColumns}
                                checkboxSelectRows={false}
                                onClick={() => handleDownload("offerInstances")}
              />
            </div>
            }
          </WithPermission> 
        </div>

      </TabPanels>

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default RewardInstances;