import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel } from '@mui/material';
import { convertPriceFromCents, currencySymbol } from 'utils/rewards';
import { t } from 'i18n/index';

const BuyActions = ({ maxAmount, onBuy, price, currency, supply_limit, amount, handleAmount, gift, handleGift}) => {

    const convertedPrice = convertPriceFromCents(price);
    const priceWithCurrency = `${parseFloat(convertedPrice)} ${currencySymbol[currency]}`;
    const orderPrice = (convertedPrice * amount).toFixed(2);
    const atMaxAmount = maxAmount === amount;
    const atMinAmount = 1 === amount;

    const GiftCheckbox = <FormControlLabel
        control={<Checkbox checked={gift}
            onChange={handleGift}
            label={t('checkoutForm:gift')}
            inputProps={{ 'aria-label': 'primary checkbox' }} />}
        label={t('checkoutForm:gift')} />;

    const AmountSelector = <div className={`buy-actions-amount`}>
        <div className={`amount-selector`}>
            <IconButton className={`MuiAmountIconButton ${atMinAmount && "MuiAmountIconButtonDisabled"}`} onClick={() => handleAmount(amount - 1)} disableRipple={true} disabled={atMinAmount}>
                <RemoveIcon />
            </IconButton>
        </div>
        <div>
            {amount}
        </div>
        <div className={`amount-selector`}>
                <IconButton className={`MuiAmountIconButton ${atMaxAmount && "MuiAmountIconButtonDisabled"}`} onClick={() => handleAmount(amount + 1)} disableRipple={true} disabled={atMaxAmount}>
                    <AddIcon />
                </IconButton>
        </div>
    </div>;

    const BuyButton = <Button className='buy-button' fullWidth variant="contained" onClick={onBuy}>
        {t('publicClaimPage:buy')}
    </Button>;

    return (
        <div className="grid buy-actions-container" >
            <div className='price'>
                {priceWithCurrency}
            </div>
            <div className='amount'>
                { AmountSelector}
            </div>
            <div className='button'>
                {BuyButton}
            </div>
            <div className='supply'>
                {supply_limit !== null && `${t('checkoutForm:inStock')} ${supply_limit}`}
            </div>
            <div className='total'>
                Total: {orderPrice} {currencySymbol[currency]}
            </div>
            <div className='gift-checkbox'>
                {GiftCheckbox}
            </div>

        </div>
    );
};

BuyActions.propTypes = {
    amount: PropTypes.number,
    handleAmount: PropTypes.func,
    maxAmount: PropTypes.number,
    onBuy: PropTypes.func,
    price: PropTypes.number,
    currency: PropTypes.string,
    stats: PropTypes.object,
    supply_limit: PropTypes.string,
    gift: PropTypes.bool,
    handleGift: PropTypes.func,
}

export default BuyActions;
