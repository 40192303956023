import React from 'react';
import PropTypes from 'prop-types';

import TextField from '../TextField';

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const ExternalPanel = ({ offer, onOfferChange, isDisabled }) => {
  
    const handleChange = ( { target: {name, value}} ) => {
        onOfferChange({
            ...offer,
            [name]: value
        });
    };

    const attributes = offer.eligible_attributes;
    const eligibleAttributes = typeof attributes === 'string' 
                                ? attributes
                                : attributes && Object.keys(attributes).length > 0
                                ? ((onOfferChange({ ...offer, eligible_attributes: JSON.stringify(attributes) })), JSON.stringify(attributes))
                                :  "";

  return (
    <div>
        <FormControl fullWidth>
            <InputLabel color="warning">Blockchain *</InputLabel>
            <Select
                fullWidth
                labelId="reward-category-label"
                label="Blockchain *"
                name="blockchain"
                color="warning"
                value={ offer.blockchain }
                disabled={ isDisabled }
                onChange={ handleChange }
                >
                <MenuItem value="ethereum">Ethereum</MenuItem>
                <MenuItem value="polygon">Polygon</MenuItem>
            </Select>
        </FormControl>

        <TextField name={ 'contract_address' }
                   label={ 'Smart Contract Address *' }
                   value={ offer.contract_address }
                   onChange={ handleChange }
                   disabled={ isDisabled } />
                   
        <TextField name={ 'eligible_attributes' }
                   label={ 'Eligibility Requirements' }
                   value={ eligibleAttributes }
                   onChange={ handleChange }
                   disabled={ isDisabled }
                   multiline={ true }
                   minRows={ 3 }
                   helperText={`Example: [{"trait_type":"Fur","value":"Paradise Green"}]`} /> 
    </div>
  );
};

ExternalPanel.propTypes = {
    offer: PropTypes.object,
    onOfferChange: PropTypes.func,
    isDisabled: PropTypes.bool,
};

export default ExternalPanel;