import React, { useState } from 'react';
import propTypes from 'prop-types';

import OfferInstanceResource from 'resources/user/OfferInstanceResource';

import polygon from '../../../assets/images/icons/polygon.png';
import ShareRewardModal from 'components/ShareRewardModal';
import StatusModal from 'components/StatusModal';
import SocialIcons from 'utils/SocialShare/ShareButtons/SocialIcons';
import { onNftScanUrl } from 'utils/urls';
import { claimState } from 'utils/constants';

import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

import { t } from 'i18n/index';
import { useController } from '@rest-hooks/react';

const PreviewAction = ({ reward, offers, onRedeem}) => {
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const { fetch } = useController();

  const claimedOnChain = reward.claim_state === claimState.ONCHAIN.value;
  const minted = reward.badge_tx !== null;
  const polygonImg = <img src={polygon} alt='polygon logo' />;

  const handleRedeem = async () => {
    if ( (reward)){
        try {
          const data = new FormData();
          data.append('offer_id', offers[0].id);
          data.append('reward_instance_id', reward.id);

          const { id, revealed_tokens } = await fetch(OfferInstanceResource.create(), data);

          // success!
          if (id){
            onRedeem(revealed_tokens);
          }

        }  catch (error) {
            const data = await error.response.json();
            handleError(data.errors.base[0]);
        }
    } else {
      handleError(t('redeemModal:onChainValidationError'));
    }
  };

  const handleError = (message) => {
    setError(message);
    setOpenErrorModal(true);
  };

  const handleCloseModals = () => {
    setOpenShareModal(false);
    setOpenErrorModal(false);
  };

  return (
    <div>
      {offers.length > 0 &&
        <div className="offers-container">
          <Button variant="contained" onClick={ handleRedeem }>{t('publicReward:openMysteryBox')}</Button>
          {error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseModals }/>}
        </div>
      }
      <div className='share-container'>
        {claimedOnChain ? (
          <ButtonGroup variant="outlined" aria-label="outlined primary button group">
            {minted && (
              <Button onClick={ () => window.open(onNftScanUrl(reward.contract_address, reward.id), "_blank") } startIcon={ polygonImg }>
                Show on chain
              </Button>
            )}
            <Button onClick={ () => setOpenShareModal(true) }>Share</Button>
            <ShareRewardModal
              open={ openShareModal }
              title={ `Share Reward` }
              description={ reward.title }
              reward={ reward }
              onClose={ handleCloseModals }
              showIcons={ true }
            />
          </ButtonGroup>
        ) : (
          <div>
            <div className='share-label'>{t('publicReward:publicRewardPage_ShareIt')}</div>
            <SocialIcons reward={ reward } url={ reward.external_url}  />
          </div>
        )}
      </div>
    </div>
  );
};

PreviewAction.propTypes = {
    reward: propTypes.object,
    offers: propTypes.array,
    onRedeem: propTypes.func
}

export default PreviewAction;
