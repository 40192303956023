import React, {useState, useEffect} from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels'
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import StatusModal from 'components/StatusModal';

import StudentResource from 'resources/organization/StudentResource';
import CollectionResource from 'resources/organization/CourseResource';

import { participantsColumns } from 'utils/columns/participantsColumns';

import { Box, Skeleton } from '@mui/material';

import { t } from 'i18n/index';

const pageInfo = {
  name: t('pageInfo:community'),
  description: t('pageInfo:communityDescription'),
};

const Students = () => {
  const { fetch } = useController();
  const params = useParams();
  const organizationId = parseInt(params.organizationId);
  const history = useHistory();

  const [participants, setParticipants] = useState([]);
  const [filteredParticipants, setFilteredParticipants] = useState([])
  const [collections, setCollections] = useState([]);
  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [collectionId, setCollectionId] = useState((new URL(document.location)).searchParams.get("collection"))

  useEffect(() => {
    const fetchData = async () => {
      try {
        
        if (collectionId) {
          const participants = await fetch(StudentResource.list(), {organization_id: organizationId, course_id: collectionId});
          setParticipants(participants);
        } else {
          const participants = await fetch(StudentResource.list(), {organization_id: organizationId});
          setParticipants(participants);
        }

        const collections = await fetch(CollectionResource.list(), {organization_id: organizationId})
        setCollections(collections);
        
        setLoading(false);
      } catch (error) {
        setError(error.message);
        handleOpenErrorModal();
      }    
    };
    fetchData();
  }, []);

  useEffect(() => {
    const lcQuery = query.toLowerCase();    
    const result = participants.filter(({first_name, last_name, email}) => {
        last_name = last_name || ''
        email = email || ''
        return (first_name.toLowerCase().includes(lcQuery) ||
            last_name.toLowerCase().includes(lcQuery) ||
            email.toLowerCase().includes(lcQuery));
    });
    setFilteredParticipants(result);
  }, [query]);

  const handleFilter = async (collectionId) => {
    setLoading(true);

    if (collectionId !== undefined) {
      const participants = await fetch(StudentResource.list(), {organization_id: organizationId, course_id: collectionId});
      setParticipants(participants);
      history.push({
        search: `?collection=${collectionId}`
      })
    } else {
      const participants = await fetch(StudentResource.list(), {organization_id: organizationId});
      setParticipants(participants);
      history.push({
        search: ``
      })
    }

    setCollectionId(collectionId);
    setLoading(false);
  };

  const handleOpenErrorModal = () => {
      setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
      setOpenErrorModal(false);
  };
  
  return (
    <Layout context='teacher'
            activeMenuItem={ 1 } 
            pageInfo={pageInfo}>
     
        <TabPanels labels={[ "Members" ]} >
          <div>
            { loading ?
              <Box sx={{ width: '100%' }}>
                <Skeleton variant="rounded" height={'70vh'} />
              </Box>
            : 
              <div style={{ flexGrow: 1 }}>
                <DataSelectionTable records={query.length > 1 ? filteredParticipants : participants}
                                columns={participantsColumns}
                                collections={collections}
                                onQueryChange={setQuery}
                                queryString={query}
                                handleFilter={handleFilter}
                                collectionId={collectionId}
                                />
              </div>
            }
          </div>
        </TabPanels>

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default Students;