import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from "react-router-dom";

import RedeemResource from 'resources/public/RedeemResource';
import ThemeResource from 'resources/public/ThemeResource';

import PublicLayout from 'components/layouts/Public';
import OffersGrid from 'containers/student/Profile/Offers/OffersGrid';
import { useLogout } from 'utils/useLogout';
import UserName from 'utils/localStorage/UserName';

import { Link } from '@mui/material';
import { t } from 'i18n/index';

const OrganizationPage = () => {
  const params  = useParams();
  const organizationId = params.organizationId;
  const offers = useSuspense(RedeemResource.list(), {organization_id: organizationId});
  const theme = useSuspense(ThemeResource.detail(), {id: organizationId})

  const history = useHistory();

  const handleRedirect = () => {
    history.push(`/offers?defaultIndex=1`)
  };

  const logout = useLogout();
  const redirect = false;
  const userName = UserName.read();
  const organizationTitle = offers.length > 0 && offers[0].organization_title

  return (
    <PublicLayout theme={ theme } organizationPage={ true } >

      <div className="header">
        <h1 className='title-color-theme'>{ organizationTitle }</h1>
        <h2>{t('offersLabels:publicOffersTitle', {organizationTitle})}</h2>
        <p>{organizationTitle ? t('offersLabels:publicOfferSubtitle', {organizationTitle}) : t('offersLabels:noOffers', {organizationTitle})}</p>
        { userName && 
          <p>
            {t('publicClaimPage:currentlyLoggedIn')}&nbsp;
            <b>{ userName }</b>.&nbsp;
            <Link underline="hover" color="inherit" onClick={ () => logout(redirect) }>{t('publicClaimPage:logOut')}</Link>
          </p>
        }
      </div>

      <OffersGrid data={offers}
                  onRedeem={handleRedirect}
                  publicView={ true }
                  />
    
    </PublicLayout>
  );
};

export default OrganizationPage;
