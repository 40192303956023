import React from "react";
import PropTypes from "prop-types";

import Image from "./cardComponents/Image";
import Header from "./cardComponents/Header";
import Details from "./cardComponents/Details";
import Button from "./cardComponents/Button";

const OrderCard = ({ item, hideDetails }) => {

  const isInvoiceReady = false;

  return (
    <div className={`order-card`}>

      <Image item={ item } hideDetails={ hideDetails } />

      <div className="order-card-details-container">
        <Header item={ item } />
        <Details item={ item } />
      </div>

      { isInvoiceReady &&
      <Button id={ item.id } />
      }

    </div>
  );
};

OrderCard.propTypes = {
  item: PropTypes.object,
  hideDetails: PropTypes.bool,
};

export default OrderCard;
