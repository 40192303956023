import React from "react";
import PropTypes from "prop-types";
import { defaultTheme } from "utils/themes";

import { isEqual } from 'lodash';

const WhitelabelFormPreview = ({ theme, hasPaddingTop }) => {
  const imageWidth = hasPaddingTop ? "100px" : "100%";
  const imageHeight = hasPaddingTop ? "" : "100px";

  const {
    layout,
    primary_background_color,
    padding_page,
    title_color,
    title_size,
    text_color,
    subtitle_size,
    description_size,
    metadata_text_color,
    metadata_box_color,
    buy_button_color,
    button_text_color,
    secondary_background_color,
    font_family,
    offer_card_background_color,
    offer_card_text_color
  } = theme;

  const offerCardContainerBackgroundColor = isEqual(theme, defaultTheme) ? secondary_background_color : theme.primary_background_color

  return (
    <div>
      <h4>NFT Claim Page Preview</h4>
      <div className={`theme-preview-container ${layout === "centered" && "column"}`}>
        <div
          className={`theme-preview-small-container ${
            layout === "sidebar" && "evenly-spaced"
          }`}
          style={{
            backgroundColor: primary_background_color,
            padding: padding_page,
          }}
        >
          <div
            style={{
              backgroundColor: primary_background_color,
              width: imageWidth,
              height: imageHeight,
              border: "solid 1px black",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h4
              style={{
                margin: "10px",
                fontFamily: font_family
              }}
            >
              {hasPaddingTop ? "Logo" : "Banner"}
            </h4>
          </div>
          <p
            style={{
              margin: "10px",
              color: title_color,
              fontSize: title_size,
              fontFamily: font_family
            }}
          >
            Title
          </p>
          <p
            style={{
              margin: "10px",
              color: text_color,
              fontSize: subtitle_size,
              fontFamily: font_family
            }}
          >
            Subtitle
          </p>
          <p
            style={{
              margin: "10px",
              color: text_color,
              fontSize: description_size,
              fontFamily: font_family
            }}
          >
            text
          </p>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: metadata_box_color,
              color: metadata_text_color,
              margin: "10px",
            }}
          >
            <h4 style={{ margin: "10px", fontFamily: font_family }}>Metadata</h4>
            <p style={{ margin: "10px", fontFamily: font_family }}>metadata</p>
          </div>
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: buy_button_color,
              color: metadata_text_color,
              margin: "10px",
              fontFamily: font_family,
            }}
          >
            <h4 style={{ margin: "10px", color: button_text_color }}>Button</h4>
          </div>

        </div>

        {layout === "sidebar" && (
          <div
            className={`theme-preview-small-container evenly-spaced`}
            style={{ backgroundColor: secondary_background_color }}
          >
            <h4
              style={{
                margin: "10px",
                color: secondary_background_color,
              }}
            >
              Secondary Background
            </h4>
          </div>
        )}
      </div>
      <h4>Offer Redeem Page Preview</h4>
      <div className={`theme-preview-container ${layout === "centered" && "column"}`}>   
        <div
          className={`theme-preview-small-container ${
            layout === "sidebar" && "evenly-spaced"
          }`}
          style={{
            backgroundColor: offerCardContainerBackgroundColor,
            width: "100%",
            marginTop: "10px",
          }}
        > 
          <div
            style={{
              borderRadius: "10px",
              backgroundColor: offer_card_background_color,
              color: offer_card_text_color,
              margin: "10px",
              padding: "30px",
              width: "80%"
            }}
          >
            <h4 style={{ margin: "10px", fontFamily: font_family }}>Offer Card</h4>
          </div>

        </div>
      </div>
    </div>
    
  );
};

WhitelabelFormPreview.propTypes = {
  theme: PropTypes.object,
  hasPaddingTop: PropTypes.bool,
};

export default WhitelabelFormPreview;
