import React from "react";
import PropTypes from "prop-types";

import LoadingButton from "components/LoadingButton";

import "@adyen/adyen-web/dist/adyen.css";

import { Button, InputAdornment, TextField } from "@mui/material";

import { t } from "i18n/index";

const EupagoPaymentForm = ({
  userPhone,
  loading,
  handleLoading,
  handleUserPhone,
  handleSubmit,
  onClose,
}) => {

  const handleChange = (event) => {
    handleUserPhone(event.target.value);
  };

  const handlePaymentAttempt = () => {
    handleLoading(true);
    handleSubmit();
  };

  return (
    <>
      {loading ? 
        <>
          <p>{t("checkoutForm:preparingYourOrder")}</p>
          <LoadingButton />
        </>
       : 
        <div>
          <TextField
            label={t("checkoutForm:phoneNumber")}
            name="userPhone"
            value={userPhone || ""}
            onChange={(e) => handleChange(e)}
            fullWidth
            margin="normal"
            color="warning"
            inputProps={{ 
              maxLength: 9,
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">+ 351</InputAdornment>,
            }}
          ></TextField>
          <div className="utrust-payment-button-group">
            <Button
              onClick={onClose}
              className="payment-button"
              variant="outlined"
            >
              {t("checkoutForm:changedMyMind")}
            </Button>
            <Button
              onClick={handlePaymentAttempt}
              className="payment-button"
              variant="contained"
            >
              {t("checkoutForm:paymentComplete")}
            </Button>
          </div>
        </div>
      }
    </>
  );
};

EupagoPaymentForm.propTypes = {
  userPhone: PropTypes.string,
  loading: PropTypes.bool,
  handleLoading: PropTypes.func,
  handleUserPhone: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleErrorFindingReward: PropTypes.func,
  onClose: PropTypes.func,
};

export default EupagoPaymentForm;
