import React from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";
import { useHistory } from "react-router-dom";

import LoadingButton from "components/LoadingButton";
import { findRewardInstance } from "utils/claimActions/payments/findRewardInstance";

import { Button, Link } from "@mui/material";

import { t } from "i18n/index";

const UtrustPaymentForm = ({ paymentIntentId,
                             redirectUrl,
                             requiresUserPrompt,
                             handleRequiresUserPrompt,
                             onClose,
                             gift, 
                             handleStep,
                             handleMessage,
                             handleErrorFindingReward,
                          }) => {

  const handleUserPrompt = () => {
    handleRequiresUserPrompt(false);
    handleMessage("");
    window.open(redirectUrl, "_blank");
  };

  const { fetch } = useController();
  const history = useHistory();
  
  const handlePaymentConfirmation = () => {
    if (!gift) { 
      handleStep(4);
      findRewardInstance(paymentIntentId, 240, 5000, fetch, history, handleErrorFindingReward);
    } else {
      handleStep(5)
    }
  };

  return (
    <div>
      {!requiresUserPrompt &&
        <>
        {paymentIntentId ? 
          <>
            <h4>{t("checkoutForm:orderStarted")}</h4>
            <p>{t("checkoutForm:utrustIntro")}</p>
            <p>{t("checkoutForm:utrustInstructions")}<Link underline="hover" color="inherit" target="_blank" rel="noopener" href={redirectUrl}>{t("globalVariables:clickHere")}</Link>.</p>
            { !requiresUserPrompt && 
              <div className="utrust-payment-button-group">
                <Button
                  onClick={onClose}
                  className="payment-button"
                  variant="outlined"
                >
                    {t("checkoutForm:changedMyMind")}
                </Button>
                <Button
                  onClick={handlePaymentConfirmation}
                  className="payment-button"
                  variant="contained"
                >
                    {t("checkoutForm:paymentComplete")}
                </Button>

              </div> 
            }
          </>
          :
          <>
            <p>{t("checkoutForm:preparingYourOrder")}</p>
            <LoadingButton />
          </>
        
        }
        </>
      }  
      {requiresUserPrompt && 
      <button
        onClick={handleUserPrompt}
        className="payment-button"
      >
        <span id="button-text">
          {t("checkoutForm:proceedToUtrust")}
        </span>
      </button>
      }
    </div>
  );
};

UtrustPaymentForm.propTypes = {
  paymentIntentId: PropTypes.string,
  redirectUrl: PropTypes.string,
  requiresUserPrompt: PropTypes.bool,
  handleRequiresUserPrompt: PropTypes.func,
  onClose: PropTypes.func,
  gift: PropTypes.bool,
  handleStep: PropTypes.func,
  handleMessage: PropTypes.func,
  handleErrorFindingReward: PropTypes.func,
};

export default UtrustPaymentForm;
