import React from 'react';
import PropTypes from 'prop-types';
import OfferCard from 'containers/student/Profile/Offers/OfferCard';

const OfferPreview = ({ item, preview, image }) => {

  return (
    <div className='offer-preview-container'>
      
      <OfferCard item={ item } preview={preview} image={image} />
      
    </div>
  );
};

OfferPreview.propTypes = {
    item: PropTypes.object,
    preview: PropTypes.bool,
    image: PropTypes.object,
};

export default OfferPreview;
