import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'i18n/index';

const Header = ({item}) => {
  return (
    <div className="order-card-header">
        <h3>{item.reward_title}</h3>
        <p>{item.reference ? `# ${item.reference}` : t('userOrders:referenceNotFound')}</p>
    </div>
  )
}

Header.propTypes = {
    item: PropTypes.object,
}

export default Header