import utrustIcon from "../../../assets/images/utrustIcon.png";
import mbwayIcon from "../../../assets/images/mbwayIcon.png";
import cardIcon from "../../../assets/images/cardIcon.png";

const paymentMethods = (handleStripePaymentIntent,
                        handleAdyenPaymentIntent,
                        handleUtrustPaymentIntent,
                        handleEupagoPaymentIntent,
                        supportedPaymentMethods
                       ) => {

  const isAppleDevice = /Macintosh|iPhone|iPad|iPod/i.test(navigator.platform);

  const googlepay = {
    name: "adyen",
    label: "Google",
    icon: "https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/medium/googlepay.png",
    onClick: () => handleAdyenPaymentIntent("google"),
  };

  const methods = [
    {
      name: "stripe",
      label: "Credit Card",
      icon: cardIcon,
      onClick: handleStripePaymentIntent,
    },
    {
      name: "adyen",
      label: "Credit Card",
      icon: cardIcon,
      onClick: () => handleAdyenPaymentIntent("card"),
    },
    {
      name: "adyen",
      label: "MB Way",
      icon: "https://checkoutshopper-live.adyen.com/checkoutshopper/images/logos/medium/mbway.png",
      onClick: () => handleAdyenPaymentIntent("mbway"),
    },
    {
      name: "utrust",
      label: "Cryptocurrency",
      icon: utrustIcon,
      onClick: handleUtrustPaymentIntent,
    },
    {
      name: "eupago",
      label: "MB Way",
      icon: mbwayIcon,
      onClick: handleEupagoPaymentIntent,
    },
  ];

  if (!isAppleDevice) {
    methods.unshift(googlepay);
  }

  const filteredMethods = methods.filter((method) => supportedPaymentMethods.includes(method.name));

  return filteredMethods;
};

export default paymentMethods;
