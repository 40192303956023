import React from 'react'
import PropTypes from 'prop-types'

import TextField from '../TextField';

const Description = ({ offer,
                       onChange,
                       isDisabled
                    }) => {
  return (
    <TextField name={ 'description' }
               label={ 'Offer Description *' }
               value={ offer.description }
               onChange={ onChange }
               disabled={ isDisabled }
               multiline={ true }
               minRows={ 3 } 
    />
  )
}

Description.propTypes = {
    offer: PropTypes.object,
    onChange: PropTypes.func,
    isDisabled: PropTypes.bool,
}

export default Description