import React from 'react'
import PropTypes from 'prop-types'

import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Select from '@mui/material/Select';

const NewOrganizationForm = ({organization, onOrganizationChange, organizationGoals}) => {

  const newOrganization = organizationGoals;
  
  const handleOrganizationChange = (event) => {
    const {name, value} = event.target;
    let newOrg = {...organization};
    newOrg[name] = value;
    onOrganizationChange(newOrg);
  };
 
  const handleCheckBoxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      onOrganizationChange({ ...organization, [name]: true });
    } else {
      onOrganizationChange({ ...organization, [name]: false });
    }
  };

  const handleFileUpload = (event) => {
    if (!event.target.files) {
        return;
      }
      const file = event.target.files[0];

      let newOrganization = {...organization};
      newOrganization['image_file'] = file;
      newOrganization['filename'] = file && file.name;
      onOrganizationChange(newOrganization);
  };
  
  return (
    <div>
      {newOrganization &&
        <div>
          <h3 className="new-form-section">Community Details</h3> 
          <p>To create your first collection, we need more details about your community to set up an account!</p>
        </div>
      }
      <div className={ `new-form-field` }>
        <TextField
          fullWidth
          name="organization_title"
          label="Community Name *"
          color="warning"
          value={ organization.organization_title || organization.title }
          onChange={handleOrganizationChange}
        />
      </div>
      {newOrganization &&
        <div>
          <div className={ `new-form-field` }>
            <FormControl fullWidth>
              <InputLabel id="course-category-label" color="warning">Community Type *</InputLabel>
              <Select 
                labelId="course-category-label"
                label="Community Type *"
                name="organization_category"
                color="warning"
                value={ organization.organization_category }
                onChange={handleOrganizationChange}
              > 
                <MenuItem value="brand">Brand</MenuItem>
                <MenuItem value="creator">Creator</MenuItem>
                <MenuItem value="business">Business</MenuItem>
                <MenuItem value="dao">DAO</MenuItem>
                <MenuItem value="nonProfitOrganization">nonProfitOrganization</MenuItem>
                <MenuItem value="informalGroup">informalGroup</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={ `new-form-field` }>
            <FormControl fullWidth>
              <InputLabel id="course-category-label" color="warning">Your Role *</InputLabel>
              <Select 
                labelId="course-category-label"
                label="Your Role *"
                name="organization_admin_role"
                color="warning"
                value={ organization.organization_admin_role }
                onChange={handleOrganizationChange}
              > 
                <MenuItem value="manager">Community Manager</MenuItem>
                <MenuItem value="leader">Leadership/Founder</MenuItem>
                <MenuItem value="creator">Individual Creator</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      }
      <div className={ `new-form-field` }>
        <TextField
          fullWidth
          name="organization_external_link"
          label="Community Website"
          color="warning"
          value={ organization.external_link  }
          onChange={handleOrganizationChange}
        />
      </div>
      <div className={ `new-form-field` }>
        <Button
          fullWidth
          component="label"
          variant="outlined"
          color="warning"
          startIcon={<UploadFileIcon />}
          helperText="Some important text"
          >
          {organization.filename ? `Uploaded ${organization.filename}` : `Upload image`}
          <input type="file" accept="image/*" hidden onChange={handleFileUpload} />
        </Button>
      </div>
      {newOrganization &&
        <div className={ `new-form-field organization-goal-field` }>
          <p>What is your main reason for using Crowdclass? *</p>
          {organizationGoals.map((goalName) => (
            <FormControlLabel
              key={goalName}
              control={
                <Checkbox
                  checked={organization[goalName]}
                  onChange={handleCheckBoxChange}
                  name={goalName}
                  color="primary"
                />
              }
              label={goalName}
            />
          ))}
        </div>
      }
    </div>
  )
}

NewOrganizationForm.propTypes = {
    organization: PropTypes.object,
    onOrganizationChange: PropTypes.func,
    organizationGoals: PropTypes.array,
}

export default NewOrganizationForm