import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@mui/material";

const OfferCardImage = ({ image, preview, title, image_url }) => {
  return (
    <div className={"offer-card-image-container"}>
      {image && (
        <img
          src={image && window.URL.createObjectURL(image)}
          className={`offer-card-image ${preview && "offer-card-image-preview"}`}
          alt={title}
        />
      )}
      {!image && image_url && (
        <img
          src={image_url}
          className={`offer-card-image ${preview && "offer-card-image-preview"}`}
          alt={title}
        />
      )}
      {!image && !image_url && (
        <Skeleton
          variant="rectangular"
          width={300}
          height={200}
          animation={false}
        />
      )}
    </div>
  );
};

OfferCardImage.propTypes = {
  image: PropTypes.object,
  preview: PropTypes.bool,
  title: PropTypes.string,
  image_url: PropTypes.string,
};

export default OfferCardImage;
