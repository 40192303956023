import React, { useState, useCallback } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import OrganizationResource from 'resources/organization/OrganizationResource';
import PublicThemeResource from 'resources/public/ThemeResource';
import ThemeResource from 'resources/organization/ThemeResource';

import TabPanels from 'components/TabPanels';
import Layout from 'components/layouts/index';
import AddMemberModal from './AddMemberModal';
import StatusModal from 'components/StatusModal';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WhitelabelFallback from 'components/fallbacks/Whitelabel';
import WithPermission from 'components/WithPermission';

import Form from './form'

import customToast from 'utils/customToast';
import { useToast } from 'utils/context/ToastContext';
import { membersColumns } from 'utils/columns/membersColumns';

import { Button } from '@mui/material';
import { t } from 'i18n/index';
import NewOrganizationForm from '../Course/Wizard/NewOrganizationForm';


const pageInfo = {
  name: t('pageInfo:settings'),
  description: t('pageInfo:settingsDescription'),
};

const Settings = () => {

  const params = useParams();
  const { fetch } = useController();
  const { setOpen, setMessage, setSeverity } = useToast();

  const [error, setError] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);
  const [previewed, setPreviewed] = useState(false);

  const organizationId = parseInt(params.organizationId);

  const savedOrganization = useSuspense(OrganizationResource.detail(), {id: organizationId});
  const [organization, setOrganization] = useState(savedOrganization);

  const themesArray = useSuspense(PublicThemeResource.list(), {id: organizationId});
  const publishedTheme = themesArray[0].published_theme;
  const draftTheme = themesArray[0].draft_theme;
  const savedTheme = draftTheme.id ? draftTheme : publishedTheme.id ? publishedTheme : null 

  const [draft, setDraft] = useState(draftTheme.id)
  const [hasBanner, setHasBanner] = useState(savedTheme && savedTheme.banner_url);
  const [hasSupportImage, setHasSupportImage] = useState(savedTheme && savedTheme.support_image_url);
  
  const handleInviteMember = useCallback(
    async (email) => {
      try {
        const { id } = await fetch(OrganizationResource.update(), { id: organizationId }, { email });

        // success!
        if (id){
          fetch(OrganizationResource.list());
          customToast('success', `New organization member invited`, setOpen, setSeverity, setMessage);
          setShowAddMemberModal(false);
        }
      }  catch (error) {
        setError(error.message); 
        handleOpenErrorModal();
      }
    },
    [fetch, organizationId],
  );

  const submitOrganizationChanges = useCallback(
    async () => {
      try {
        const {organization_title, organization_external_link, image_file} = organization;
        const formData = new FormData();
        if (organization_title !== undefined && organization_title !== "") formData.append('title', organization_title)
        if (organization_external_link !== undefined) formData.append('external_link', organization_external_link)
        if (image_file) formData.append('image', image_file)
        const { id } = await fetch(OrganizationResource.update(), { id: organization.id }, formData );

        // success!
        if (id){
          fetch(OrganizationResource.list());
          customToast('success', `Organization Details Updated`, setOpen, setSeverity, setMessage);
        }
      }  catch (error) {
        setError(error.message); 
        handleOpenErrorModal();
      }
    },
    [fetch, organization],
  );

  const handleOpenErrorModal = () => {
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  const handleSize = (size) => {
    return size.toString().includes("px") ? size : `${size}px`
  };

  const createFormData = (style, bannerFile, supportImageFile) => {
    const formData = new FormData();
    const fields = [
      { key: 'layout', value: style.layout },
      { key: 'primary_background_color', value: style.primary_background_color },
      { key: 'banner_size', value: style.banner_size},
      { key: 'title_color', value: style.title_color },
      { key: 'buy_button_color', value: style.buy_button_color },
      { key: 'button_text_color', value: style.button_text_color },
      { key: 'text_color', value: style.text_color },
      { key: 'metadata_text_color', value: style.metadata_text_color },
      { key: 'metadata_box_color', value: style.metadata_box_color },
      { key: 'secondary_background_color', value: style.secondary_background_color },
      { key: 'title_size', value: handleSize(style.title_size) },
      { key: 'subtitle_size', value: handleSize(style.subtitle_size) },
      { key: 'description_size', value: handleSize(style.description_size) },
      { key: 'padding_page', value: style.padding_page },
      { key: 'font_family', value: style.font_family },
      { key: 'offer_card_background_color', value: style.offer_card_background_color },
      { key: 'offer_card_text_color', value: style.offer_card_text_color },
    ];

    if (bannerFile) {
      fields.push({ key: 'banner', value: bannerFile });
    }

    if (supportImageFile){
      fields.push({ key: 'support_image', value: supportImageFile });
    }

    fields.forEach(field => {
      formData.append(field.key, field.value);
    });
  
    return formData;
};

  const handlePreview = async (e, style, bannerFile, supportImageFile) => {
    try {
      e.preventDefault();
      const formData = createFormData(style, bannerFile, supportImageFile);
      const { id } = await fetch(ThemeResource.update(), {organization_id: organizationId}, formData);
      // success!
      if (id){
        setPreviewed(true);
        window.open( `/claim/${organizationId}/preview`, "_blank");
      }
    } catch (error) {
      setError(error.message); 
      handleOpenErrorModal();
    }
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const { id } = await fetch(ThemeResource.create(), {organization_id: organizationId}, {});
      if (id){
        customToast('success', `Theme saved`, setOpen, setSeverity, setMessage);
        setPreviewed(false)
        setDraft(false)
        fetch(PublicThemeResource.list(), {id: organizationId})
      }
    } catch (error) {
      setError(error.message); 
      handleOpenErrorModal();
    }
  };

  const handleReset = async () => {
    try {
      await fetch(ThemeResource.delete(), {organization_id: organizationId})
      fetch(PublicThemeResource.list(), {id: organizationId})
      window.location.reload()
    } catch (error) {
      setError(error.message); 
      handleOpenErrorModal();
    }
  };
  
  const handleDiscardDraft = async () => {
    try {
      await fetch(ThemeResource.delete(), {organization_id: organizationId, id: draftTheme.id})
      fetch(PublicThemeResource.list(), {id: organizationId})
      window.location.reload()
    } catch (error) {
      setError(error.message); 
      handleOpenErrorModal();
    }
  };

  const handleEditing = () => {
    setPreviewed(false)
    setDraft(true)
  };

  return (
    <Layout context='teacher'
            activeMenuItem={5}
            pageInfo={pageInfo}
            >

      <TabPanels labels={ [`Details`, `Members`, `Theme`] } >
        <form id="admin-courses-add" onSubmit={ submitOrganizationChanges } >
          <NewOrganizationForm organization={organization}
                               onOrganizationChange={ setOrganization }
          />
          <div className={ `new-form-field` }>
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
      </div>
        </form>
        <div>
          <p>Team members have accounts on Crowdclass and can create new collections and NFTs or change their settings.</p>            

          <DataSelectionTable records={organization.managers}
                            columns={membersColumns}
                            checkboxSelectRows={false}
          />

          <Button variant="contained" color="primary" onClick={ () => setShowAddMemberModal(true) }>Add Member</Button>
          <AddMemberModal open={ showAddMemberModal }
                          onSubmit={ handleInviteMember }
                          onClose={ () => setShowAddMemberModal(false) } />
        </div>
        <div>
          <WithPermission permission={'hasWhitelabel'} fallback={<WhitelabelFallback />}>
            <Form record={savedTheme && savedTheme.theme} 
                  previewed={previewed} 
                  handlePreview={ handlePreview } 
                  onSubmit={handleSubmit} 
                  handleReset={handleReset} 
                  handleDiscardDraft={handleDiscardDraft}
                  hasBanner={hasBanner}
                  handleBanner={() => setHasBanner(false)}
                  hasSupportImage={hasSupportImage}
                  handleSupportImage={() => setHasSupportImage(false)}
                  draft={draft}
                  handleEditing={handleEditing}
            />
          </WithPermission>
        </div>
      </TabPanels>

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

    </Layout>
)};

export default Settings;