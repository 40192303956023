import React from 'react'
import PropTypes from 'prop-types'

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { t } from 'i18n/index';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { appendHiddentData } from '../../../../../utils/appendHiddentData';

const PostRedeemContent = ({ redeemedContent, token}) => {
    const { id, content_text, content_text_link, user } = redeemedContent || {};

    const link = appendHiddentData(id, content_text_link, user, token);

    return (
        <div>
            <DialogContent dividers >
                <ReactMarkdown>{content_text}</ReactMarkdown>
            </DialogContent>

            { content_text_link &&
                <DialogActions>
                    <Button variant="contained"
                            color="warning"
                            classes='MuiButton-outlinedWarning'
                            onClick={ () => window.open(link, '_blank') }>
                        {t('merch:redirectButton')}
                    </Button>
                </DialogActions>
            }
        </div>
    )
}

PostRedeemContent.propTypes = {
    redeemedContent: PropTypes.object,
    token: PropTypes.object,
};

export default PostRedeemContent;
