import React from 'react'
import PropTypes from 'prop-types'

import { t } from 'i18n/index';
import moment from 'moment';
import isMobileDevice from 'utils/mobileAndTabletCheck';

const OfferCardDetails = ({ available_amount, redeem_limit, end_date, preview}) => {

    const isMobile = isMobileDevice();

    return (
        <div className='offer-card-details'> 
            {(!isMobile && available_amount && end_date && !preview) && 
                <p>{available_amount}&nbsp; / {redeem_limit} {t('offersLabels:offerCardHeader')}  |  {t('offersLabels:offerCardExpirationDate')} {moment(end_date).format('lll')}</p>
            }
            
            {(isMobile && available_amount && !preview) && <p>{available_amount}&nbsp; / {redeem_limit} {t('offersLabels:offerCardHeader')}</p>} 

            {(preview && redeem_limit) && <p>{redeem_limit}&nbsp; / {redeem_limit} {t('offersLabels:offerCardHeader')}</p>} 

            {((isMobile || preview) && end_date) && <p>{t('offersLabels:offerCardExpirationDate')} {moment(end_date).format('lll')}</p>}
            
        </div>
  )
};

OfferCardDetails.propTypes = {
    available_amount: PropTypes.string,
    redeem_limit: PropTypes.string,
    end_date: PropTypes.string,
    preview: PropTypes.bool,
};

export default OfferCardDetails