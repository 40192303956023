import React from 'react';
import PropTypes from 'prop-types';
import { Link, useParams} from "react-router-dom";

import logo from 'assets/images/logo-crowdclass.svg';

import LastCourse from 'utils/localStorage/LastCourse';

import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import PeopleIcon from '@mui/icons-material/People';
import WidgetsIcon from '@mui/icons-material/Widgets';
import SettingsIcon from '@mui/icons-material/Settings';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import StoreIcon from '@mui/icons-material/Store';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';

const Teacher = ({ organizationsList, activeMenuItem=0 }) => {

  const params = useParams();

  const organizationId = params.organizationId;
  const currentOrganization = organizationsList && organizationsList.find(org => org.id === parseInt(organizationId));
  const organizationTitle = currentOrganization && currentOrganization.title;
 
  const course_links = [
    { text: 'Community', Icon: PeopleIcon, link: 'participants' },
    { text: 'Reports & Analytics', Icon: WidgetsIcon, link: 'analytics' },
    { text: 'Offers', Icon: RedeemTwoToneIcon, link: 'offers' },
    { text: 'Sales', Icon: StoreIcon, link: 'orders' },
    { text: 'Admin Settings', Icon: SettingsIcon, link: 'settings' },
  ];

  const visitedCourses = LastCourse.read();
  const lastVisitedCourse = visitedCourses && visitedCourses[0];
  const lastOrganization = visitedCourses && Object.keys(lastVisitedCourse)[0];
  const lastCourse = visitedCourses && lastVisitedCourse[lastOrganization];

  return (
    <div>
      <Toolbar className="drawer-logo">
        <img src={ logo } />
      </Toolbar>

      <h2 className='center orange'>{organizationTitle}</h2>
      <ListItemButton key="NFT Collections"
                      selected={ 0 == activeMenuItem}
                      component={Link}
                      disabled={!organizationId}
                      to={ `/organizations/${lastOrganization}/collections/${lastCourse}`}
                      >
        <ListItemIcon>
          <LocalPoliceIcon />
        </ListItemIcon>
        <ListItemText primary={ "NFT Collections" } />
      </ListItemButton>
      {course_links.map(({text, Icon, link}, secondaryIndex) => (
      <ListItemButton key={text}
                      selected={ secondaryIndex + 1 == activeMenuItem}
                      component={Link}
                      disabled={!organizationId}
                      to={ `/organizations/${organizationId}/${link}`}
                      >
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={ text } />
      </ListItemButton>
      ))}

    </div>
  );
};

Teacher.propTypes = {
  organizationsList: PropTypes.array,
  activeMenuItem: PropTypes.number,
};

export default Teacher;