import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CustomizationBar from './CustomizationBar';
import DraggableElement from './Draggable';

import 'assets/css/template.css';
import 'assets/css/template-fonts.css'
import { IconButton, Tooltip } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';

import { placeholders } from './consts';

function dynamicElemConfig(y, x, font, size, weight, color, align, ratio) {
  return {
    "top": `${parseInt(y)}px`, 
    "left": `${parseInt(x)}px`, 
    "font": font, 
    "size": `${parseInt(size * (ratio))}px`, 
    "align": align, 
    "color": color, 
    "weight": weight
  }
}

const CustomBoard = ({imageRealWidth, imageRealHeight, imageWidth, imageHeight, onSave, onClose}) => {

    const [showStudent, setShowStudent] = useState(false);
    const [showDate, setShowDate] = useState(false);
    const [showSerialNumber, setShowSerialNumber] = useState(false);

    const defaultStyle = {exists: false, resizing: false, font: 'Helvetica', size: 24, weight: 'normal', align: 'left', color: 'black' };
    const [activeItem, setActiveItem] = useState({item: 'name', ...defaultStyle });
    const [name, setName] = useState({active: activeItem === 'name', ...defaultStyle });
    const [date, setDate] = useState({active: activeItem === 'date', ...defaultStyle });
    const [serialNumber, setSerialNumber] = useState({active: activeItem === 'serial_number', ...defaultStyle });

    const [nameControlledPosition, setControlledNamePosition] = useState({x: 0, y: 0});
    const [dateControlledPosition, setControlledDatePosition] = useState({x: 0, y: 0});
    const [serialNumberControlledPosition, setControlledSerialNumberPosition] = useState({x: 0, y: 0});

    const [nameRealPosition, setNameRealPosition] = useState({x: 0, y: 0});
    const [dateRealPosition, setDateRealPosition] =  useState({x: 0, y: 0});
    const [serialNumberRealPosition, setSerialNumberRealPosition] =  useState({x: 0, y: 0});

    const widhtRatio = imageRealWidth / imageWidth;

    const handleItemChange = (event, x) => {
        let variable;
        if (event.target.value === undefined){
          variable = x;
        } else {
          variable = event.target.value;
        }
        switch(variable) {
          case "name":
            setActiveItem({ ...activeItem, item: variable, font: name.font, size: name.size, weight: name.weight, align: name.align, color: name.color});
          break;
          case "date":
            setActiveItem({ ...activeItem, item: variable, font: date.font, size: date.size, weight: date.weight, align: date.align, color: date.color});
            break;
          case "serial_number":
            setActiveItem({ ...activeItem, item: variable, font: serialNumber.font, size: serialNumber.size, weight: serialNumber.weight, align: serialNumber.align, color: serialNumber.color});
            break;
        }
    };

    const handleFontChange = (event) => {
        setActiveItem({...activeItem, font: event.target.value });
        switch(activeItem.item) {
          case "name":
            setName({ ...name, font : event.target.value});
          break;
          case "date":
            setDate({ ...date, font : event.target.value});
            break;
          case "serial_number":
            setSerialNumber({ ...serialNumber, font : event.target.value});
            break;
        }
    };

    const handleColorChange = (color) => {
        setActiveItem({...activeItem, color : color.hex }); 
        switch(activeItem.item) {
          case "name":
            setName({ ...name, color : color.hex});
          break;
          case "date":
            setDate({ ...date, color : color.hex});
            break;
          case "serial_number":
            setSerialNumber({ ...serialNumber, color : color.hex});
            break;
        }
    };

    const handleSizeChange = (event) => {
        setActiveItem({ ...activeItem, size : +event.target.value});
        switch(activeItem.item) {
          case "name":
            setName({ ...name, size : +event.target.value});
          break;
          case "date":
            setDate({ ...date, size : +event.target.value});
            break;
          case "serial_number":
            setSerialNumber({ ...serialNumber, size : +event.target.value});
            break;
        }
    };

    const handleWeightChange = (event) => {
        setActiveItem({ ...activeItem, weight : event.target.value});
        switch(activeItem.item) {
          case "name":
            setName({ ...name, weight : event.target.value});
          break;
          case "date":
            setDate({ ...date, weight : event.target.value});
            break;
          case "serial_number":
            setSerialNumber({ ...serialNumber, weight : event.target.value});
            break;
        }
    };

    const handleAlignmentChange = () => {
      switch(activeItem.item) {
        case "name":
          if (name.align === 'left'){
            setControlledNamePosition({x:0, y:0});
            setActiveItem({ ...activeItem, align : 'center'});
            setName({ ...name, align : 'center'});
          } else {
            setActiveItem({ ...activeItem, align : 'left'});
            setName({ ...name, align : 'left'});
          }
        break;
        case "date":
          if (date.align === 'left'){
            setControlledDatePosition({x: 0, y:0});
            setActiveItem({ ...activeItem, align : 'center'});
            setDate({ ...date, align : 'center'});
          } else {
            setActiveItem({ ...activeItem, align : 'left'});
            setDate({ ...date, align : 'left'});
          }
          break;
        case "serial_number":
          if (serialNumber.align === 'left'){
            setControlledSerialNumberPosition({x: 0, y:0 });
            setActiveItem({ ...activeItem, align : 'center'});
            setSerialNumber({ ...serialNumber, align : 'center'});
          } else {
            setActiveItem({ ...activeItem, align : 'left'});
            setSerialNumber({ ...serialNumber, align : 'left'});
          }
          break;
      }
    };

    const handleClick = () => {
      if (activeItem.item === 'name') {
        setShowStudent(!showStudent);
        setName({ ...name, exists : !showStudent});
      }
      if (activeItem.item === 'date') {
        setShowDate(!showDate);
        setDate({ ...date, exists : !showDate});
      }
      if (activeItem.item === 'serial_number') {
        setShowSerialNumber(!showSerialNumber);
        setSerialNumber({ ...serialNumber, exists : !showSerialNumber});
      }
      handleButton();
    };

    const handleButton = () => {
      switch(activeItem.item) {
        case "name":
          return name.exists;
        case "date":
          return date.exists;
        case "serial_number":
          return serialNumber.exists;
      }
    };

      const onControlledDragStop = (e, position) => {
        const {x, y} = position;
        if (e.target.innerText === placeholders.name){
          setControlledNamePosition({x, y});
        }
        if (e.target.innerText === placeholders.date){
          setControlledDatePosition({x, y});
        }
        if (e.target.innerText === placeholders.serial){
          setControlledSerialNumberPosition({x, y});
        }
      };

      useEffect(() => {
        if (name.exists){
          const nameRealX = nameControlledPosition.x * (widhtRatio);
          const nameRealY = nameControlledPosition.y * (widhtRatio);
          setNameRealPosition({ x: nameRealX , y: nameRealY });
        }
        if (date.exists){
          const dateRealX = dateControlledPosition.x * (widhtRatio);
          const dateRealY = dateControlledPosition.y * (widhtRatio);
          setDateRealPosition({ x: dateRealX , y: dateRealY });
        }
        if (serialNumber.exists){
          const serialNumberRealX = serialNumberControlledPosition.x * (widhtRatio);
          const serialNumberRealY = serialNumberControlledPosition.y * (widhtRatio);
          setSerialNumberRealPosition({ x: serialNumberRealX , y: serialNumberRealY });
        }
      }, [nameControlledPosition, dateControlledPosition, serialNumberControlledPosition ]);

      const handleSave = () => {
        const dynamicElements = {"image": {"width": `${imageRealWidth}px`, "height": `${imageRealHeight}px`}};
        if (name.exists){
          dynamicElements['name'] = dynamicElemConfig(nameRealPosition.y, nameRealPosition.x, name.font, name.size, name.weight, name.color, name.align, widhtRatio);
        }
        if (date.exists) {
          dynamicElements['date'] = dynamicElemConfig(dateRealPosition.y, dateRealPosition.x, date.font, date.size, date.weight, date.color, date.align, widhtRatio);
        }
        if (serialNumber.exists) {
          dynamicElements['serial'] = dynamicElemConfig(serialNumberRealPosition.y, serialNumberRealPosition.x, serialNumber.font, serialNumber.size, serialNumber.weight, serialNumber.color, serialNumber.align, widhtRatio);
        }
        onSave(dynamicElements);
        onClose();
      };

  return (
    <>
      <Tooltip title="Save">
        <IconButton
          aria-label='save'
          onClick={handleSave}
          sx={{
            position: 'absolute',
            right: 48,
            top: 8,
            color: 'black',
          }}
          >
            <SaveIcon fontSize="large" color="warning"/>
        </IconButton>
      </Tooltip>
      <CustomizationBar activeItem={activeItem}
                        handleItemChange={handleItemChange}
                        handleClick={handleClick}
                        handleButton={handleButton}
                        handleFontChange={handleFontChange}
                        handleSizeChange={handleSizeChange}
                        handleWeightChange={handleWeightChange}
                        handleColorChange={handleColorChange}
                        handleAlignmentChange={handleAlignmentChange}
                        handleSave={handleSave}
      />

      <div style={{width: imageWidth, height: imageHeight, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', border: 'solid 2px #e55e29', background: 'transparent', zIndex: 1,}}>
        {showStudent && 
          <DraggableElement position={nameControlledPosition}
                            handleItemChange={handleItemChange}
                            onStop={onControlledDragStop}
                            item='name'
                            src={name}
                            text={ placeholders.name }
                            imageWidth={imageWidth}
          />
        } 
        {showDate &&
          <DraggableElement position={dateControlledPosition}
                            handleItemChange={handleItemChange}
                            onStop={onControlledDragStop}
                            item='date'
                            src={date}
                            text={ placeholders.date }
                            imageWidth={imageWidth}
          />
        }
        {showSerialNumber && 
          <DraggableElement position={serialNumberControlledPosition}
                            handleItemChange={handleItemChange}
                            onStop={onControlledDragStop}
                            item='serial_number'
                            src={serialNumber}
                            text={ placeholders.serial }
                            imageWidth={imageWidth}
          />
        }
      </div>
    </>
  );
}

CustomBoard.propTypes = {
  imageRealHeight: PropTypes.number,
  imageRealWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  imageWidth: PropTypes.number,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
};

export default CustomBoard;