import React, { useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import Layout from 'components/layouts';
import TabPanels from 'components/TabPanels';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import StatusModal from 'components/StatusModal';
import OffersFallback from 'components/fallbacks/Offers';
import OrganizationOfferResource from 'resources/organization/OfferResource';

import { activeOffersColumns } from 'utils/columns/activeOffersColumns';
import { finalizedOffersColumns } from 'utils/columns/finalizedOffersColumns';
import copyToClipboard from 'utils/copyToClipboard';
import { useToast } from 'utils/context/ToastContext';
import { offersShareUrl } from 'utils/urls';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { t } from 'i18n/index';
import moment from 'moment';

const pageInfo = {
    name: t('pageInfo:offers'),
  description: t('pageInfo:offersDescription'),
}

const Offers = () => {
    const [activeOffers, setActiveOffers] = useState([])
    const [finalizedOffers, setFinalizedOffers] = useState([])
    const [error, setError] = useState(null);
    const [openErrorModal, setOpenErrorModal] = useState(false);

    const { fetch } = useController();
    const history = useHistory();
    const { setOpen, setMessage, setSeverity } = useToast();

    const params = useParams();
    const organizationId = parseInt(params.organizationId);
    

    useEffect(() => {
      const fetchData = async () => {
        try {
            const offers = await fetch(OrganizationOfferResource.list(), {organization_id: organizationId});
            // const activeOffers = offers.filter(offer => (offer.end_date ? moment(offer.end_date) >= moment(Date.now()) : true) && (offer.redeem_limit ? offer.redeemed < offer.redeem_limit : true))
            
            const isActiveOffer = (offer) => {
                const isEndDateValid = !offer.end_date || moment(offer.end_date) >= moment(Date.now());
                const isRedeemLimitValid = !offer.redeem_limit || offer.redeemed < offer.redeem_limit;
                return isEndDateValid && isRedeemLimitValid;
            }
            
            const activeOffers = offers.filter(isActiveOffer);
            setActiveOffers(activeOffers);
        
            // const finalizedOffers = offers.filter(offer =>  (offer.end_date ? moment(offer.end_date) < moment(Date.now()) : false) || (offer.redeem_limit ? offer.redeemed >= offer.redeem_limit : false));
            const isFinalizedOffer = (offer) => {
                const isEndDateValid = offer.end_date && moment(offer.end_date) < moment(Date.now());
                const isRedeemLimitValid = offer.redeem_limit && offer.redeemed >= offer.redeem_limit;
                return isEndDateValid || isRedeemLimitValid;
            }
              
            const finalizedOffers = offers.filter(isFinalizedOffer);
            setFinalizedOffers(finalizedOffers)

        } catch (error) {
            setError(error.message);
            handleOpenErrorModal();
        }    
      };
     
      fetchData();
    }, [])

    const newOfferButton =    { icon : AddCircleOutlineIcon, 
                                function: () => history.push(`/organizations/${organizationId}/offers/new`),
                                text: "Create New Offer" }
    
    const shareOffersButton = { icon : ContentCopyIcon, 
                                function: () => copyToClipboard(offersShareUrl(organizationId), setOpen, setSeverity, setMessage),
                                text: "Copy Share Link" }

    const organizationView = [newOfferButton, shareOffersButton]
    
    const handleOpenErrorModal = () => {
        setOpenErrorModal(true);
    };
    
    const handleCloseErrorModal = () => {
        setOpenErrorModal(false);
    };

    const labels = [`Active Offers (${activeOffers.length})`, `Finalized Offers (${finalizedOffers.length})`]

    return (
        <Layout context='teacher'
                activeMenuItem={ 3 }
                pageInfo={pageInfo}>
            
            <WithPermission permission={'hasOffers'} fallback={<OffersFallback />}>
                <TabPanels labels={labels} >
                    <div key={0}>
                        <DataSelectionTable records={activeOffers}
                                columns={activeOffersColumns}
                                checkboxSelectRows={false}
                                organizationView={organizationView}
                        />
                    </div>
                    <div key={1}>
                        <DataSelectionTable records={finalizedOffers}
                                columns={finalizedOffersColumns}
                                checkboxSelectRows={false}
                                organizationView={organizationView}
                        />
                    </div>
                </TabPanels>
            </WithPermission>        
            { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ handleCloseErrorModal }/>}

        </Layout>
    );
};

export default Offers;