import React, { useState } from 'react';
import { useController, useSuspense } from '@rest-hooks/react';

import UserResource from 'resources/admin/UserResource';
import OrganizationUserResource from 'resources/admin/OrganizationUserResource';
import OrganizationResource from 'resources/admin/OrganizationResource';

import TabPanels from 'components/TabPanels';
import StatCard from 'components/StatCard';
import Layout from 'components/layouts/index';
import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { usersColumns } from 'utils/columns/admin/usersColumns';
import { organizationUsersColumns } from 'utils/columns/admin/organizationUsers';
import LoadingTable from 'containers/teacher/RewardInstance/components/LoadingTable';

const pageInfo = {
  label: "Admin",
  name: "Users",
}

const AdminUsers = () => {

  const [loading, setLoading] = useState(false);
  const [organizationUsers, setOrganizationUsers] = useState( useSuspense(OrganizationUserResource.list()) );
  const [organizationId, setOrganizatioId] = useState(null);
  const [userId, setUserId] = useState(null);

  const { fetch } = useController();

  const users = useSuspense(UserResource.list());
  const organizations = useSuspense(OrganizationResource.list())
  const allOrganizationUsers = useSuspense(OrganizationUserResource.list());

  const handleFilter = async (organizationId, userId) => {
    setLoading(true);

    let organizationUsers
    if (organizationId) {
      organizationUsers = await fetch(OrganizationUserResource.list(), {organization_id: organizationId});
    } else if (userId) {
      organizationUsers = await fetch(OrganizationUserResource.list(), {user_id: userId});
    } else {
      organizationUsers = await fetch(OrganizationUserResource.list());
    }

    setOrganizationUsers(organizationUsers)
    setOrganizatioId(organizationId)
    setUserId(userId)
    setLoading(false);
  };
  
  const getUniqueArray = (arr, key) => {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  };

  return (
    <Layout context='admin'
            pageInfo={ pageInfo }
            activeMenuItem={1}
            statOverview={
              <StatCard type='number'
                        highlight={ users.length }
                        title='Users'
              />
            } >
      
      <TabPanels labels={[ "Users", "Organization Users" ]} >
            <div>
              <DataSelectionTable records={users}
                                  columns={usersColumns}
                                  checkboxSelectRows={false}
              />
            </div>
            <div>
              { loading ? 
                <LoadingTable />
                : 
                <DataSelectionTable records={organizationUsers}
                                    columns={organizationUsersColumns}
                                    checkboxSelectRows={false}
                                    handleFilter={handleFilter}
                                    organizations={organizations}
                                    users={getUniqueArray(allOrganizationUsers, "email")}
                                    organizationId={organizationId}
                                    userId={userId}
                />
              }
            </div>
      </TabPanels>

    </Layout>
)};

export default AdminUsers;