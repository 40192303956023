import React, { useState } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import WhitelabelFormPreview from "./WhitelabelFormPreview";
import ConfirmDialog from 'components/ConfirmDialog';

import { colorItems, fontSizeItems, fontFamilyOptions } from "utils/whitelabelConsts";
import { defaultTheme } from "utils/themes";

import {  IconButton,  InputAdornment,  MenuItem,  TextField,  Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FormatAlignCenter from "@mui/icons-material/FormatAlignCenter";
import FormatAlignLeft from "@mui/icons-material/FormatAlignLeft";
import PhotoSizeSelectLargeIcon from '@mui/icons-material/PhotoSizeSelectLarge';
import PhotoIcon from '@mui/icons-material/Photo';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Alert from '@mui/material/Alert';

import { CompactPicker } from "react-color";

const WhitelabelForm = ({ record = {}, onSubmit, handlePreview, handleReset, previewed, handleDiscardDraft, hasBanner, handleBanner, hasSupportImage, handleSupportImage, draft, handleEditing}) => {
  const [theme, setTheme] = useState({ ...defaultTheme, ...record });
  const [activeItem, setActiveItem] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);

  const handleItemChange = (event) => {
    const value = event.target.value;
    setActiveItem(value);
  };

  const handleAlignmentChange = () => {
    let newTheme = { ...theme };
    if (theme.layout === "centered") {
      newTheme["layout"] = "sidebar";
    } else {
      newTheme["layout"] = "centered";
    }
    handleEditing();
    setTheme(newTheme);
  };

  const handleSizeChange = (event) => {
    let newTheme = { ...theme };
    newTheme[activeItem] = +event.target.value;
    handleEditing();
    setTheme(newTheme);
  };

  const hasPaddingTop = theme.padding_page.split(" ")[0] === "80px";

  const handlePaddingChange = () => {
    let padding_page = theme.padding_page;
    if (hasPaddingTop) {
      padding_page = `0px 60px 160px`;
    } else {
      padding_page = `80px 60px 160px`;
    }
    let newTheme = { ...theme };
    newTheme["padding_page"] = padding_page;
    handleEditing();
    setTheme(newTheme);
  };

  const handleThemeChange = (event, color, item) => {
    let newTheme = { ...theme };
    const { name, value } = event.target;
    if (color) {
      newTheme[item] = color.hex;
    } else {
      newTheme[name] = value;
    }
    handleEditing();
    setTheme(newTheme);
  };

  const handleBannerSize = (banner_file) => {
    return new Promise((resolve) => {

    const file = banner_file;
    const reader = new FileReader();

    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width > 600) {
          resolve("100%");
        } else {
          resolve(`${img.width}px`);
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  })};

  const handleFileUpload = async (event) => {
    const {files, name} = event.target;
    let newTheme = { ...theme };

    if (!files) {
      return;
    }

    if (name === "banner") {
      const banner_file = files[0];
      const bannerSize = await handleBannerSize(banner_file);
      newTheme["banner_size"] = bannerSize;
      newTheme["banner_file"] = banner_file;
      newTheme["banner_filename"] = banner_file.name;
    }
    if (name === "supportImage") {
      const support_image_file = files[0];
      newTheme["support_image_file"] = support_image_file;
      newTheme["support_image_filename"] = support_image_file.name;
    }
    handleEditing();
    setTheme(newTheme);
  };

  const handleRemoveImage = (image) => {
    let newTheme = { ...theme };

    if (image === "banner") {
      newTheme["banner_size"] = "220px";
      newTheme["banner_file"] = "delete";
      newTheme["banner_filename"] = "";
      newTheme["padding_page"] = "80px 60px 160px";
      handleBanner();
    } else {
      newTheme["support_image_file"] = "delete";
      newTheme["support_image_filename"] = "";
      handleSupportImage();
    }
    handleEditing();
    setTheme(newTheme);
  };

  const handleConfirm = () => {
    setConfirmModal(true);
  };

  const confirmReset = () => {
    handleEditing();
    setTheme({...defaultTheme});
    handleReset();
  };

  return (
    <div>

      <WhitelabelFormPreview theme={theme} hasPaddingTop={hasPaddingTop} />

      <form
        onSubmit={(e) => onSubmit(e, theme, theme["banner_file"], theme["support_image_file"])}
      >
        <h4>Layout</h4>

        <div className="theme-form-row">
          <p>Layout: </p>
          <IconButton
            onClick={handleAlignmentChange}
            style={{
              border: "solid 1px #BDBDBD",
              borderRadius: 5,
              padding: 7,
              margin: 10,
            }}
            disabled={theme.layout !== "sidebar"}
          >
            <Tooltip title="Align Center">
                <FormatAlignCenter color={theme.layout !== "sidebar" ? "disabled" : "warning"} />
            </Tooltip>
          </IconButton>
          <IconButton
            onClick={handleAlignmentChange}
            style={{
              border: "solid 1px #BDBDBD",
              borderRadius: 5,
              padding: 7,
              margin: 10,
            }}
            disabled={theme.layout === "sidebar"}
          >
            <Tooltip title="Align Left">
              <FormatAlignLeft color={theme.layout === "sidebar" ? "disabled" : "warning"} />
            </Tooltip>
          </IconButton>
        </div>

        <h4>Colors and Sizes</h4>

        <div  className="theme-form-row">
          <div className={`theme-form-row inner`}>
            <TextField
              fullWidth
              select
              value={activeItem}
              label={"Select to change color"}
              onChange={handleItemChange}
              color={"warning"}
            >
              {colorItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: 75 }}
              select
              value={theme[activeItem]}
              InputProps={{
                startAdornment: (
                  <InputAdornment>
                    <div
                      style={{
                        backgroundColor: activeItem
                          ? theme[activeItem]
                          : "#F75201",
                        width: 20,
                        height: 20,
                      }}
                    ></div>
                  </InputAdornment>
                ),
              }}
              color={"warning"}
            >
              <CompactPicker
                color={theme.primary_background_color}
                onChangeComplete={(color, event) =>
                  handleThemeChange(event, color, activeItem)
                }
              />
            </TextField>
          </div>

          <div className="theme-form-row inner">
            <TextField
              fullWidth
              select
              value={activeItem}
              label={"Select to change font size"}
              onChange={handleItemChange}
              color={"warning"}
            >
              {fontSizeItems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              sx={{ width: 75 }}
              type="number"
              label="Size"
              value={activeItem ? parseInt(theme[activeItem], 10) : ""}
              onChange={handleSizeChange}
              color={"warning"}
            />
          </div>

          <div className="theme-form-row inner">
            <TextField
              fullWidth
              select
              value={theme.font_family}
              name="font_family"
              label={"Change Font Family"}
              onChange={(event) =>
                  handleThemeChange(event)
                }
              color={"warning"}
            >
              {fontFamilyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value} sx={{ fontFamily: `${option.value}` }}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>

        </div>

        <h4>Media</h4>

        <div className="theme-form-row">
          <p>Top Image: </p>
          <IconButton
            onClick={handlePaddingChange}
            style={{
              border: "solid 1px #BDBDBD",
              borderRadius: 5,
              padding: 7,
              margin: 10,
            }}
            disabled={!hasPaddingTop}
          >
              <Tooltip title="Banner">
                <PhotoIcon color={!hasPaddingTop ? "disabled" : "warning"} />
              </Tooltip>
          </IconButton>
          <IconButton
            onClick={handlePaddingChange}
            style={{
              border: "solid 1px #BDBDBD",
              borderRadius: 5,
              padding: 7,
              margin: 10,
            }}
            disabled={hasPaddingTop}
          >
            <Tooltip title="Logo">
                <PhotoSizeSelectLargeIcon color={hasPaddingTop ? "disabled" : "warning"} />
              </Tooltip>
          </IconButton>
        </div>

        <div className="theme-form-row">
          <div className={`theme-form-upload-buttons`}>
            <Button
              fullWidth
              component="label"
              variant="outlined"
              color="warning"
              startIcon={<UploadFileIcon />}
            >
              {theme.banner_filename ? `Uploaded ${theme.banner_filename}` : hasBanner ? `Replace Banner/Logo` : `Upload Banner/Logo`}
              <input
                name="banner"
                type="file"
                accept="image/*"
                hidden
                onChange={(event) => handleFileUpload(event)}
              />
            </Button>
            { (theme.banner_filename || hasBanner) &&
              <Tooltip title={(theme.banner_filename && !hasBanner) ? "Delete Uploaded File" : "Delete Saved File"}>
                <IconButton color="warning" onClick={() => handleRemoveImage("banner")}>
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>
            }
          </div>

          <div className={`theme-form-upload-buttons`}>
            <Button
              fullWidth
              component="label"
              variant="outlined"
              color="warning"
              startIcon={<UploadFileIcon />}
            >
              {theme.support_image_filename ? `Uploaded ${theme.support_image_filename}` : hasSupportImage ? `Replace Background Image` : `Upload Background Image`}
              <input
                name="supportImage"
                type="file"
                accept="image/*"
                hidden
                onChange={(event) => handleFileUpload(event)}
              />
            </Button>
            { (theme.support_image_filename || hasSupportImage) && 
              <Tooltip title={(theme.support_image_filename && !hasSupportImage) ? "Delete Uploaded File" : "Delete Saved File"}>
                <IconButton color="warning" onClick={() => handleRemoveImage("support_image")}>
                  <HighlightOffIcon />
                </IconButton>
              </Tooltip>  
            }
          </div>
        </div>

        {draft &&
            <Alert severity="warning" className="theme-form-row">
              <div className="theme-form-row">
                <p>You are seeing a draft. Discard this theme here or continue editing before publishing.</p>
                <Button
                  variant="contained"
                  onClick={handleDiscardDraft}
                >
                  Discard Draft
                </Button>
              </div>
            </Alert>
        }
        <div className={`new-form-field-footer`}>
          <Button
            variant="contained"
            onClick={handleConfirm}
          >
            Reset
          </Button>
          <Tooltip title={ "Saves new draft" } >
            <Button
              variant="contained"
              component={Link}
              target="_blank"
              to={`/claim/preview`}
              onClick={(e) => handlePreview(e, theme, theme["banner_file"], theme["support_image_file"])}
            >
              Preview
            </Button>
          </Tooltip>
          <Button
            variant="contained"
            type="submit"
            disabled={!previewed}
          >
            Publish
          </Button>
        </div>
      </form>

      <ConfirmDialog
        title={`Reset theme?`}
        open={ confirmModal }
        onConfirm={ confirmReset }
        setOpen={ setConfirmModal }
        >
        Resetting your organization&apos;s theme to the default Crowdclass Theme will permanently remove any customizations you have made. Are you sure you want to proceed with the reset?
      </ConfirmDialog>

    </div>
  );
};

WhitelabelForm.propTypes = {
  record: PropTypes.object,
  onSubmit: PropTypes.func,
  handlePreview: PropTypes.func,
  handleReset: PropTypes.func,
  previewed: PropTypes.bool,
  handleDiscardDraft: PropTypes.func,
  hasBanner: PropTypes.bool,
  handleBanner: PropTypes.func,
  hasSupportImage: PropTypes.bool,
  handleSupportImage: PropTypes.func,
  draft: PropTypes.bool,
  handleEditing: PropTypes.func,
};

export default WhitelabelForm;
