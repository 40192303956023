import React from 'react'
import PropTypes from 'prop-types'

import ConnectWalletButton from 'components/authForms/WalletConnect/ConnectWalletButton';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { styled } from '@mui/material/styles';
import { Button, Divider } from '@mui/material';

import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { t } from 'i18n/index';

const CustomAccordion = styled((props) => (
    <Accordion disableGutters={true} elevation={0} {...props} className='offer-card-background-color-theme'/>
        ))(() => ({
            '&:not(:last-child)': { borderBottom: 0 },
            '&:before': { display: 'none' },
    }));

const OfferCardAccordion = ({ description,
                              title,
                              textOfferInstance,
                              externalOfferInstance,
                              contentText,
                              onClick,
                              preview,
                              loading }) => {

    const showRevealButton = !preview && textOfferInstance && !contentText;
    const content = contentText ? null : description;

    return (
        <CustomAccordion>
            <AccordionSummary>
                <div className='accordion-content'>
                    <h3>{title}</h3>
                    <Divider sx={{ my: 1 }}/>
                        { showRevealButton && <> {
                            externalOfferInstance ?
                                <ConnectWalletButton challengeType='reveal' done={ onClick } /> :
                                <Button variant="contained"
                                classes='MuiButton-outlinedWarning'
                                onClick={ onClick }>
                                {t('offersLabel:reveal')}
                                </Button>
                            } </>
                        }
                        {!textOfferInstance && <p>{t('offersLabel:description')}</p>}
                </div>
            </AccordionSummary>
            <AccordionDetails className='accordion-content'>
                <ReactMarkdown>{ loading ? t('offersLabels:revealContentLoading'): content }
                </ReactMarkdown>
            </AccordionDetails>
        </CustomAccordion>

    )
};

OfferCardAccordion.propTypes = {
    description: PropTypes.string,
    title: PropTypes.string,
    textOfferInstance: PropTypes.bool,
    externalOfferInstance: PropTypes.bool,
    contentText: PropTypes.string,
    contentTextLink: PropTypes.string,
    onClick: PropTypes.func,
    preview: PropTypes.bool,
    loading: PropTypes.bool,
    offerInstanceId: PropTypes.number,
    user: PropTypes.object,
    token: PropTypes.object,
};

export default OfferCardAccordion;
