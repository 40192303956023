import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '@mui/material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { t } from 'i18n/index';

const OfferCardFooter = ({ state, showRedeemButton, onRedeem, preview}) => {
  return (
    <>
        {state &&
            <div className={`offer-card-footer ${preview && 'offer-card-footer-preview'}`}>
                <CheckBoxIcon className='hidden green'/>
                <h4 className='green'>{t('offersLabels:offerCardLabelRedeemedState')}</h4>
                { state === 'initiated' &&
                    <p>{t('offersLabels:offerCardLabelRedeemedStateConfirmation')}</p>
                }
            </div>
        }

        <div className={`offer-card-footer ${preview && 'offer-card-footer-preview'}`}>
            {showRedeemButton &&
                <Button variant="contained" 
                        onClick={ onRedeem }
                        disabled={ preview }>
                        {t('offersLabels:offerCardButton')}
                </Button>
            }            
        </div>
    </>
    
  )
};

OfferCardFooter.propTypes = {
    state:PropTypes.string,
    showRedeemButton: PropTypes.bool,
    onRedeem: PropTypes.func,
    preview: PropTypes.bool,
};

export default OfferCardFooter