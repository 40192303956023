import React, { useState } from "react";
import PropTypes from "prop-types";

import RedeemResource from 'resources/public/RedeemResource';
import ContentResource from "resources/user/ContentResource";

import RedeemModal from "./RedeemModal";
import AuthModal from 'components/authForms/AuthModal';
import StatusModal from "components/StatusModal";

import Image from "./cardComponents/OfferCardImage";
import Accordion from "containers/student/Profile/Offers/cardComponents/OfferCardAccordion";
import Details from "./cardComponents/OfferCardDetails";
import Rewards from "./cardComponents/OfferCardRewards";
import Footer from "./cardComponents/OfferCardFooter";

import AuthToken from 'utils/localStorage/AuthToken';
import { offerType } from "utils/offersFormHelper";
import { useController } from "@rest-hooks/react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { appendHiddentData } from 'utils/appendHiddentData';

import { t } from 'i18n/index';
import { Button } from "@mui/material";

const OfferCard = ({ item, image, preview, publicView, onRedeem }) => {
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [openAuthModal, setOpenAuthModal] = useState(false);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [redeemedContent, setRedeemedContent] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { fetch } = useController();

  const showRedeemButton = !item.redeem_state;
  const showEligibilityCriteria = !preview

  const authToken = AuthToken.read();
  const handlePublicRedeem = (item) => {
    if (authToken) {
      handleRedeemAttempt(item)
    } else {
      setOpenAuthModal(true);
    }
  };

  const handleRedeemAttempt = async (offer) => {
    try {
      const response = await fetch(RedeemResource.list(), {organization_id: offer.organization_id});
      const foundOffer = response.find(element => element.id === offer.id);
      const eligible = foundOffer["eligible"]
      setOpenAuthModal(false);
      if (eligible) {
        setOpenRedeemModal(true);
      } else {
        setError(t('offersLabels:notEligible'));
        setOpenErrorModal(true);
      }
    } catch (error) {
      setError(error.message);
      setOpenErrorModal(true);
    }
  };

  const textOfferInstance = !!item.redeem_state && item.text_offer

  const buildFormData = ( id, wallet_address, signature, challenge) =>{
    const data = new FormData();
    data.append('wallet_address', wallet_address);
    data.append('signature', signature);
    data.append('challenge', challenge);
    return data
  };

  const handleContentRevealRequest = async ({ wallet_address, challenge, signature }) => {
    try {
      setLoading(true);
      const data = buildFormData(item.id, wallet_address, signature, challenge);
      const redeemedContent = await fetch(ContentResource.create(), {id: item.id}, data);
      setRedeemedContent(redeemedContent);
    } catch (error) {
      const { errors } = await error.response.json();
      const errorMessage = errors ? errors.base[0] : error.message;
      setError(errorMessage);
      setOpenErrorModal(true);
    }
    setTimeout(() => {
      setLoading(false);
    }, 3500);
  };

  const showContentButton = redeemedContent && redeemedContent.content_text_link && !preview;
  const link = redeemedContent && appendHiddentData(redeemedContent.id, redeemedContent.content_text_link, redeemedContent.user, redeemedContent.token);

  return (
    <div className={`offer-card ${preview && 'offer-card-preview'}`} key={item.id}>
      <Image image={ image } preview={ preview } title={ item.title } image_url={ item.image_url } />

      <div>
        <Accordion title={item.title}
                   description={ item.description}
                   textOfferInstance={textOfferInstance}
                   externalOfferInstance={!item.internal}
                   contentText={redeemedContent && redeemedContent.content_text}
                   contentTextLink={redeemedContent && redeemedContent.content_text_link}
                   onClick={ handleContentRevealRequest }
                   preview={preview}
                   loading={loading}
                   offerInstanceId={redeemedContent && redeemedContent.id}
                   user={redeemedContent && redeemedContent.user}
                   token={redeemedContent && redeemedContent.token}
        />

        { redeemedContent && /* had to move this here because it was getting hidden inside the accordion */
          <div style={{marginLeft: '20px'}}>
            <ReactMarkdown>{ redeemedContent.content_text }</ReactMarkdown>
              {showContentButton &&
                  <Button variant="outlined"
                  color="warning"
                  classes='MuiButton-outlinedWarning'
                  onClick={ () => window.open(link, '_blank') }>
                          {t('merch:redirectButton')}
                  </Button>
              }
          </div>
        }

        <Details
          available_amount={item.available_amount}
          redeem_limit={item.redeem_limit}
          end_date={item.end_date}
          preview={preview}
        />

        {showEligibilityCriteria &&
          <Rewards
            item={ item }
            publicView={publicView}
          />
        }

        <Footer
          state={item.redeem_state}
          showRedeemButton={showRedeemButton}
          onRedeem={publicView ? () => handlePublicRedeem(item) : () => setOpenRedeemModal(true)}
          preview={preview}
        />
      </div>

      <RedeemModal open={ openRedeemModal }
                   title={ item.title }
                   reward={ item.eligible_rewards && item.eligible_rewards.length ? item.eligible_rewards[0] : null }
                   course={ item.reward_course && item.reward_course.title }
                   onClose={  () => setOpenRedeemModal(false)}
                   offerId={ item.id }
                   internal={ item.offer_type === offerType.internal }
                   onRedeem={ onRedeem }
                   />

      <AuthModal open={ openAuthModal }
                 onClose={ () => setOpenAuthModal(false) }
                 done={ () => handleRedeemAttempt(item) }
                 offer={ item }
                 />

      { error && <StatusModal message={ error } open ={ openErrorModal } onClose={ () => setOpenErrorModal(false) }/>}

    </div>
  );
};

OfferCard.propTypes = {
  item: PropTypes.object,
  image: PropTypes.object,
  preview: PropTypes.bool,
  publicView: PropTypes.bool,
  onRedeem: PropTypes.func,
};

export default OfferCard;
