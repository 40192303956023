import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";

import LoadingButton from 'components/LoadingButton';

import { t } from "i18n/index";

const utrustConfirmation =
   <>
    <p>{t('checkoutForm:utrustAwaitingConfirmationParagraph1')}</p>
    <p>{t('checkoutForm:utrustAwaitingConfirmationParagraph2')}</p>
    <p>{t('checkoutForm:utrustAwaitingConfirmationParagraph3')}</p>
  </>;

const mbwayConfirmation =
   <>
    <p>{t('checkoutForm:mbwayAwaitingConfirmationParagraph1')}</p>
    <p>{t('checkoutForm:mbwayAwaitingConfirmationParagraph2')}</p>
  </>;

const defaultConfirmation = <p>{t('checkoutForm:lookingForRewardInstance')}</p>;


const confirmations = {
  utrust: utrustConfirmation,
  mbway: mbwayConfirmation,
}

const ProcessingPayment = ({ errorFindingReward, gift, paymentProvider }) => {
  const confirmation = confirmations[paymentProvider] || defaultConfirmation;

  return (
    <div>
        {!errorFindingReward ?
            <div>
              <h4>{t('checkoutForm:thankYouForPayment')}</h4>
              {gift ?
                <p>{t('checkoutForm:processingOrder')}</p>
              :
                <>{ confirmation }</>
              }
              <LoadingButton />
            </div>
            :
            <p><Link style={{ color: "inherit", textDecoration: "inherit" }} to="/profile">{t('checkoutForm:rewardInstanceNotFound')}</Link></p>
        }
    </div>
  )
}

ProcessingPayment.propTypes = {
    errorFindingReward: PropTypes.bool,
    gift: PropTypes.bool,
    paymentProvider: PropTypes.string,
}

export default ProcessingPayment;

