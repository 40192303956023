import React, { useState } from 'react';
import PropTypes from 'prop-types';

import RewardPreview from 'components/RewardPreview/index';
import TemplateModal from 'containers/teacher/Reward/Wizard/Template/TemplateModal';

import { courseType, rewardType } from 'utils/constants';
import { capitalize } from 'utils/capitalize';
import { convertPriceFromCents } from 'utils/rewards';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import EuroIcon from '@mui/icons-material/Euro';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { Box, Checkbox, FormControlLabel, Stack, Tooltip, Typography, InputAdornment } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';

import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers';

import moment from 'moment';

const defaultReward = { title: '', description: '', template:false, public:'', image_file: null, image_filename: '', video_file: null, video_filename: null, end_date: null, active_state: true, supply_limit: null, price: null, currency: "EUR", media_type: "image", hide_details: false };
const emptyBadge = { category: 'behaviour', ...defaultReward };
const emptyCertificate = { category: 'achievement', ...defaultReward };
const emptyCollectible = { category: 'collectible', ...defaultReward };
const emptyProperty = { key: '', value: '' };

const RewardForm = ({record={}, course, onSubmit, isDisabled, loading, mysteryBoxReward, permissions, published}) => {

  const [ paid, setPaid ] = useState(record.price > 0)

  const mysteryBox = course.category === courseType.MYSTERYBOX.value;
  const collectible = course.category === courseType.SELLABLECOLLECTIBLES.value || (mysteryBox && paid);
  const certificate = course.category === courseType.COURSE.value;

  const metadata = record.metadata || [];

  const [reward, setReward] = useState(collectible ? {...emptyCollectible, ...record} : certificate ? {...emptyCertificate, ...record } : {...emptyBadge, ...record });
  const [properties, setProperties] = useState([ ...metadata, {...emptyProperty } ]);
  const [image, setImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [templateVars, setTemplateVars] = useState({});
  const [mediaTypeImage, setMediaTypeImage] = useState(record.media_type ? record.media_type === "image" : true);
  const [shownPrice, setShownPrice] = useState(convertPriceFromCents(record.price));

  const handleRewardChange = (event) => {
    let newReward = {...reward};
    const {name, value, checked} = event.target;

    if (name === "category" ) {
      if (value === "achievement") {
        setMediaTypeImage(true)
      } else {
        setMediaTypeImage(false)
      }
    }

    if (name === "price") {
      setShownPrice(value)
    }

    if (name === 'template' && checked) {
      setOpen(true);
    }

    if (name === 'collectible_switcher') {
      setPaid(!paid)
    }

    newReward[name] = (name === 'template' || name === 'active_state' || name === 'hide_details' )
                              ? checked
                              : value;
    setReward(newReward);
  };

  const handleDateTimeChange = (newValue) => {
    let newReward = {...reward};
    newReward['end_date'] = newValue;
    setReward(newReward);
  };

  const handleFileUpload = (event) => {
    if (!event.target.files) {
      return;
    }
    if (event.target.name === "image"){
      const image_file = event.target.files[0];
      let newReward = {...reward};
      newReward['image_file'] = image_file;
      newReward['image_filename'] = image_file && image_file.name;
      setReward(newReward);
      setImage(event.target.files[0]);
    }
    if (event.target.name === "video"){
      const video_file = event.target.files[0];
      let newReward = {...reward};
      newReward['video_file'] = video_file;
      newReward['video_filename'] = video_file && video_file.name;
      setReward(newReward);
    }
  };

  const handlePropertyChange = (event, index) => {
    let data = [...properties];
    data[index][event.target.name] = event.target.value;
    setProperties(data);
  };

  const addProperty = (index) => {
    let data = [... properties];
    data.splice(index+1, 0, {...emptyProperty });
    setProperties(data);
  };

  const removeProperty = (index) => {
    let data = [...properties];
    data.splice(index, 1);
    setProperties(data);
  };

  const handleCloseTemplateModal = () => {
    setOpen(false);
  };

  const handleTemplateUpdate = (vars) => {
    setTemplateVars(vars);
  };

  const handleMediaTypeChange = () => {
    let newReward = {...reward};
    if (mediaTypeImage){
      newReward["media_type"] = "video"
    } else {
      newReward["media_type"] = "image"
    }
    setReward(newReward);
    setMediaTypeImage(!mediaTypeImage)
  }

  const currencyIcon = {"EUR" : <EuroIcon />, "USD" : <AttachMoneyIcon />, "GBP" : <CurrencyPoundIcon />}
  const priceRegex = /^(?=.*[1-9])\d{0,6}(\.\d{1,2})?$/

  return (
    <div className='reward-creation-container'>
      <form id="admin-rewards-add"
        onSubmit={ (e) => onSubmit(e,
                                   reward,
                                   properties,
                                   reward['image_file'],
                                   reward['video_file'],
                                   templateVars) } >

        <div className="new-reward-wrapper">
          <div className={ `new-form-field` }>
                  <TextField
                    autoFocus
                    fullWidth
                    name="title"
                    label={`${capitalize(rewardType[reward.category.toUpperCase()].label)} Title *`}
                    color="warning"
                    value={ reward.title }
                    onChange={event => handleRewardChange(event)}
                    disabled={isDisabled}
                  />
          </div>
          <div className={ `new-form-field` }>
                    <TextField
                      fullWidth
                      multiline
                      minRows={ 3 }
                      name="description"
                      label={`${capitalize(rewardType[reward.category.toUpperCase()].label)} Description`}
                      color="warning"
                      value={ reward.description }
                      onChange={event => handleRewardChange(event)}
                      disabled={isDisabled}
                    />
          </div>

          { (mysteryBoxReward && permissions.hasSales) &&
            <div className={`new-form-field`}>
              <FormGroup>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography>Free</Typography>
                  <FormControlLabel control={<Switch
                                    name='collectible_switcher'
                                    color="warning"
                                    checked={collectible}
                                    onChange={event => handleRewardChange(event)}
                                    disabled={isDisabled}
                                  />}
                  />
                  <Typography>Paid</Typography>
                </Stack>
              </FormGroup>
            </div>
          }
          { collectible &&
            <>
              <div className={ `new-form-field` }>
                     <TextField
                        error={!priceRegex.test(shownPrice) && shownPrice !== null}
                        fullWidth
                        name="price"
                        label={!priceRegex.test(shownPrice) && shownPrice !== null ? "Please set a price in the following format: 123456.12" :  "Collectible price *"}
                        color="warning"
                        value={shownPrice}
                        onChange={event => handleRewardChange(event)}
                        onPaste={event => event.preventDefault()}
                        disabled={isDisabled}
                        InputProps={{
                          endAdornment: <InputAdornment>{currencyIcon[reward.currency]}</InputAdornment>,
                        }}
                      />
              </div>
              <div className={ `new-form-field` }>
                      <FormControl fullWidth>
                        <InputLabel id="reward-category-label" color="warning">Currency</InputLabel>
                        <Select
                          fullWidth
                          labelId="reward-category-label"
                          label="Currency"
                          name="currency"
                          color="warning"
                          value={ reward.currency }
                          onChange={event => handleRewardChange(event)}
                          disabled={isDisabled}
                        >
                          <MenuItem value="EUR">Euros €</MenuItem>
                          <MenuItem value="USD">American Dollars $</MenuItem>
                          <MenuItem value="GBP">British Pound £</MenuItem>
                        </Select>
                      </FormControl>
              </div>
            </>
          }
          { !isDisabled &&
            <>
              <h3 className="new-form-section">Media</h3>

              { !certificate &&
                <Stack direction="row" spacing={0.5} alignItems="center" alignSelf="center">
                  <Typography>Video</Typography>
                  <Switch color="warning"
                          checked={mediaTypeImage}
                          onChange={handleMediaTypeChange}
                  />
                  <Typography>Image</Typography>
                </Stack>
              }

              <div className={ `new-form-field column` }>
                        <Button
                          fullWidth
                          component="label"
                          variant="outlined"
                          color="warning"
                          startIcon={<UploadFileIcon />}
                        >
                          Upload image *
                          <input name="image" type="file" accept="image/*" hidden onChange={event => handleFileUpload(event)} />
                        </Button>
                        <p>{reward.image_filename}</p>
              </div>

              { !mediaTypeImage &&
                <div className={ `new-form-field column` }>
                          <Button
                            fullWidth
                            component="label"
                            variant="outlined"
                            color="warning"
                            startIcon={<UploadFileIcon />}
                          >
                            Upload video *
                            <input name="video" type="file" accept="video/*" hidden onChange={event => handleFileUpload(event)} />
                          </Button>
                          <p>{reward.video_filename}</p>
                </div>
              }

              { certificate &&
                <div className={`new-form-field`}>
                  <Box>
                    <FormControlLabel
                      label= {`Dynamic Elements ${!reward.image_filename && !reward.image_url ? '- upload image' : ''}`}
                      disabled = {reward.image_filename === '' && reward.image_url === undefined }
                      control={<Checkbox
                                name="template"
                                checked={reward.template}
                                onChange={event => handleRewardChange(event)}
                              />}
                    />
                  </Box>
                </div>
              }
            </>
          }

          <h3 className="new-form-section">Claim Link Settings</h3>

          {!mysteryBox &&
            <div className={ `new-form-field` }>
              <FormControl fullWidth>
                <InputLabel id="reward-category-label" color="warning" >QR Code / Link settings *</InputLabel>
                <Select
                  labelId="reward-category-label"
                  label="QR Code / Link settings"
                  name="public"
                  color="warning"
                  value={ reward.public }
                  onChange={event => handleRewardChange(event)}
                  disabled={isDisabled}
                >
                  <MenuItem value={true}> {`Public - Anyone with the link can claim the ${capitalize(rewardType[reward.category.toUpperCase()].label)} `}</MenuItem>
                  <MenuItem value={false}>{`Private - Only users in the contact Whitelist can claim the ${capitalize(rewardType[reward.category.toUpperCase()].label)} `}</MenuItem>
                </Select>
              </FormControl>
            </div>
          }
          <div className={ `new-form-field` }>
            <TextField
              fullWidth
              name="supply_limit"
              label={`Total ${mysteryBoxReward ? "collection" : ""} supply${ mysteryBox ? " *" : ". Leave empty for unlimited supply"}`}
              color="warning"
              value={ reward.supply_limit }
              type="number"
              onChange={event => handleRewardChange(event)}
              disabled={published}
            />
          </div>
          { (!mysteryBox || mysteryBoxReward) &&
            <>
              <div className={ `new-form-field` }>
                      <TextField
                        fullWidth
                        name="claim_limit"
                        label={`Max amount per user. Leave empty for 1`}
                        color="warning"
                        value={ reward.claim_limit }
                        type="number"
                        onChange={event => handleRewardChange(event)}
                        disabled={published}
                      />
              </div>
              <div className={`new-form-field`} >
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker label="Expiration date. Leave empty for no expiration."
                                    value={reward.end_date}
                                    onChange={handleDateTimeChange}
                                    minDate={moment().toDate()}
                                    renderInput={(params) => <TextField {...params} color="warning" fullWidth />}
                                    disabled={published}
                    />
                  </LocalizationProvider>
              </div>
            </>
          }
          { !mysteryBox &&
                <div className={`new-form-field`}>
                  <Box>
                    <FormControlLabel
                      label= {`Hide Details in Claim Page`}
                      control={<Checkbox
                                name="hide_details"
                                checked={reward.hide_details}
                                onChange={event => handleRewardChange(event)}
                              />}
                    />
                  </Box>
                </div>
              }

          { !mysteryBoxReward &&
            <>
              <h3 className="metadata-title">{capitalize(rewardType[reward.category.toUpperCase()].label)} Attributes</h3>
              <p className='metadata-description' >Create custom attributes uniquely for this NFT. These will then be shown as unique Metadata on your NFT. You can have multiple attributes with the same name and different values.</p>

              {properties.map((property, index) => {
                return (
                <div className="property-row" key={ `property-${index}` }>
                  <TextField
                    fullWidth
                    name="key"
                    label="Attribute Name"
                    color="warning"
                    value={ property.key }
                    onChange={event => handlePropertyChange(event, index)}
                    disabled={isDisabled}
                  />

                  <TextField
                    fullWidth
                    name="value"
                    label="Attribute Value"
                    color="warning"
                    value={ property.value }
                    onChange={event => handlePropertyChange(event, index)}
                    disabled={isDisabled}
                  />

                  { !isDisabled &&
                    <div className='property-buttons'>
                      <Tooltip title="Delete Attribute" placement="top">
                        <IconButton aria-label="remove" onClick={() => removeProperty(index)} style={{padding:0}}>
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Add Attribute">
                        <IconButton aria-label="remove" onClick={() => addProperty(index)} style={{padding:0}}>
                          <AddCircleOutlineOutlinedIcon color='warning' />
                        </IconButton>
                      </Tooltip>
                    </div>
                  }
                </div>
                )
              })}
            </>

          }

          <div className={ `new-form-field-footer` }>
            {loading ?
              <CircularProgress color="warning"/>
            :
              <Button variant="contained" color="primary" type="submit" disabled={published} >
                { !record.id ? 'Create' : 'Save' }
              </Button>
            }
            { !mysteryBox &&
              <FormGroup>
                <Stack direction="row" spacing={0.5} alignItems="center">
                  <Typography>Inactive</Typography>
                  <FormControlLabel control={<Switch
                                    name='active_state'
                                    color="warning"
                                    checked={reward.active_state}
                                    onChange={event => handleRewardChange(event)}
                                  />}
                  />
                  <Typography>Active</Typography>
                </Stack>
              </FormGroup>
            }
          </div>
        </div>
      </form>

      <div className='reward-preview'>
        <div className='badge-labels'>
            <Chip label="PREVIEW" variant='outlined' className='preview' />
        </div>

        <RewardPreview  title={ reward.title === '' ? 'Title' : reward.title }
                        image={ image }
                        image_url={reward.image_url}
                        description={ reward.description }
                        metadata={ properties.filter( ({key, value}) => key && value) }
                        created_at={ Date.now()}
                        reward_type={ reward.category }
                        organization={ course.organization }
                        activity_title={ course.title }
                        activity_type={ course.category }
                        media_type={ reward.media_type }
        />
      </div>
      <TemplateModal onSave={handleTemplateUpdate} imgSource = {image ? window.URL.createObjectURL(image) : reward.template_image_url } open={open} title={reward.title === '' ? 'Title' : reward.title} onClose={ handleCloseTemplateModal } style={ { width: '80%', height: '80%' } }/>
    </div>
  );
};

RewardForm.propTypes = {
  record: PropTypes.object,
  onSubmit: PropTypes.func,
  course: PropTypes.object,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  mysteryBoxReward: PropTypes.bool,
  permissions: PropTypes.object,
  published: PropTypes.bool,
};

export default RewardForm;
