const orgs = {
  57: "tvi",
  81: "he",
  83: "nativiz",
  86: "slb"
}

const getPublishableKey = (organizationId, provider) => {
  const env = process.env.REACT_APP_WEB_ENV || process.env.NODE_ENV; // fix for staging env - dont remove
  const org = env === 'production' ? orgs[organizationId] : "CC";
  const key = `REACT_APP_${org}_${provider}_KEY`;
  return process.env[key.toUpperCase()];
};

export default getPublishableKey;
