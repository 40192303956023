import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactGA from "react-ga4";
import logo from 'assets/images/logo-crowdclass.svg'
import setCustomTheme from 'utils/setCustomTheme'
import { defaultTheme, themeClasses } from 'utils/themes';
import { t } from 'i18n/index';

import 'assets/css/layouts.css';
import 'assets/css/themes.css';

const setBackgroundColor = () => {
  document.documentElement.style.setProperty('--primaryBackgroundColor', "#fdecef")
}

const PublicLayout = ({ isLogin=false, theme, children, organizationPage }) => {
  const env = process.env.REACT_APP_WEB_ENV || process.env.NODE_ENV; // fix for staging env - dont remove
  if (env === 'production') {
    const { pathname } = window.location;
    useEffect(() => {
      ReactGA.initialize("G-BT63R86WTL"); // For some odd reason, the initialize is already triggering a pageview
      // ReactGA.send({ hitType: "pageview", page: pathname }); // This is not needed, as the initialize already triggers a pageview
    }, [pathname])
  }

  const [logoSwitch, setLogoSwitch] = useState(logo)
  const [classLayout, setClassLayout] = useState("layout-sidebar")
  const additionalClasses = themeClasses(isLogin, theme, classLayout);
  const showRightPane = classLayout === "layout-sidebar" && !organizationPage ? true : false;

  useEffect(() => {
    if (theme && theme.theme) {
      setLogoSwitch(theme.banner_url ? theme.banner_url : logo );
      setCustomTheme(theme.theme, theme.support_image_url);
      setClassLayout(`layout-${theme.theme.layout}`);
    } else if (organizationPage) {
      setBackgroundColor()
    }
    return  () => {
      setLogoSwitch(logo);
      setCustomTheme(defaultTheme);
    }
  }, [])

   return (
    <div className={additionalClasses}>
        <div className="left-pane">
          <div className={`wrapper ${organizationPage && 'offer-wrapper'}`}>
            <div className="logo">
              <img src={ logoSwitch } alt='logo'/>
            </div>

            <div className="content">
                { children }

                { !isLogin &&
                  <p className="no-account">
                    {t('publicClaimPage:aboutCrowdclassTextFirst')} <br />
                    {t('publicClaimPage:aboutCrowdclassTextSecond')} <a href="https://crowdclass.com" target="_blank" rel="noreferrer">{t('publicClaimPage:aboutCrowdclasscheckItHere')}</a>
                  </p>
                }
            </div>
          </div>
        </div>

      { showRightPane &&
        <div className={"right-pane"}></div>
      }

    </div>
  );
};

PublicLayout.propTypes = {
  isLogin: PropTypes.bool,
  theme: PropTypes.object,
  children: PropTypes.node,
  organizationPage: PropTypes.bool,
}
export default PublicLayout;
