const airtable = "https://airtable.com"

export function appendHiddentData(offer_instance_id, link, user, token) {
    if (link && !link.includes(airtable)) {
        return link;
    }

    const { first_name = "", last_name = "", email = "", id = "" } = user || {};
    const name = `${first_name} ${last_name}`.trim().replace(/\s/g, "%20");

    const fields = [
        `prefill_Nome=${name}`,
        `prefill_Email=${email}`,
        `prefill_user_id=${id}`,
        `prefill_offer_instance_id=${offer_instance_id}`,
        `prefill_token_id=${token.id}`,
        `prefill_token_serial=${token.serial_number}`,
        "hide_offer_instance_id=true",
        "hide_user_id=true",
        "hide_token_id=true",
        "hide_token_serial=true"
    ];

    return `${link}?${fields.join("&")}`;
}
